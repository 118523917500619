import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { useRef } from 'react'
import {Link} from 'react-router-dom'
import { BiCopy,BiCheckDouble  } from 'react-icons/bi'
import { RiCloseCircleLine } from 'react-icons/ri';
import { userContext } from '../../Context/UserContextProvider'



function Modal({refer_star,open,setOpen,refId,content,share,courseId,typeShare}) {
  // console.log('modal is called');
  console.log(courseId);
  console.log(content);
  console.log(typeShare);
    // const [open, setOpen] = useState(false)
    // const handleShow = (e) => setOpen(true)
    const cancelButtonRef = useRef(null)
    const {
      sharemodel, setSharemodel,
      }=React.useContext(userContext)

    //author:Nitesh
    const [Textcopy, setTextcopy] = React.useState(false);
    function handleCopyClick(Copydata){
         navigator.clipboard.writeText(Copydata);
         setTextcopy(true)
         setTimeout(() => {
          setTextcopy(false);
        }, 2000);
    };
    // const handleCopyClick = async () => {
    //   try {
    //     await navigator.clipboard.writeText(text);
     
    //     alert('Copied to clipboard: ' + text);
    //   } catch (err) {
    //     console.error('Unable to copy to clipboard.', err);
    //   }
    // };
    return (
        <>
            <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" >
            
            </div>
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <i className='text-3xl flex justify-end me-3 mt-3 text-[#775EA5] hover:text-red-500 cursor-pointer' onClick={()=>{setSharemodel(false)}}><RiCloseCircleLine/></i>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  {Textcopy && <h5 className='flex justify-center items-center position-absolute top-5 left-[40%] text-success '><BiCheckDouble />Copied!</h5>}
                    <div className="refer_modal">
                     
                      {/* <img src={refer_star} className="img-fluid" /> */}
                      <h2>{!content ?"Refer & Earn Up to 150 Points": content}</h2>
                      {/* <p>{!content&&'Earn 50 Points Every Time a referred Friend '}</p> */}
                      {/* <button className="copy_btn"
                      onChange={(e) => setText(e.target.value) }
                      
                      >
                        {refresponseData.id} <BiCopy className="icons" onClick={ () => handleCopyClick()} />{' '}
                      </button> */}
                     {
                      refId ?  <button
                        className="copy_btn"
                        // onClick={() => setText(refId)}
                      >
                        {refId} 
                       
                        {Textcopy ? <BiCheckDouble className="icons" onClick={()=>handleCopyClick(refId)} /> : <BiCopy className="icons" onClick={()=>handleCopyClick(refId)} />}
                        
                      </button> : 
                     ( typeShare === "category" )
                      ?
                      <button
                      className="copy_btn"
                      // onClick={() => setText(`https://navyaedu.com/Similar-Course?catrgory_id=${courseId}`)}
                    >
                      {courseId} {Textcopy ?  <BiCheckDouble className="icons" onClick={()=>handleCopyClick(`https://navyaedu.com/Similar-Course?catrgory_id=${courseId}`)} />
                      :
                        <BiCopy className="icons" onClick={()=>handleCopyClick(`https://navyaedu.com/Similar-Course?catrgory_id=${courseId}`)} />}
                      
                    </button>
                      :
                      typeShare === "course"
                      &&
                      <button
                        className="copy_btn"
                        // onClick={() => setText(`https://navyaedu.com/course/${courseId}`)}
                      >
                        {courseId} 
                        {
                          Textcopy ?
                          <BiCheckDouble className="icons" onClick={()=>handleCopyClick(`https://navyaedu.com/course/${courseId}`)} />
                          :
                          <BiCopy className="icons" onClick={()=>handleCopyClick(`https://navyaedu.com/course/${courseId}`)} />
                        }
                        
                      </button>
                     }

                      

                      {/* <h1>{text === '' ? <BiCopy className="icons" onClick={()=>setText(refresponseData.id)} /> : <TiTick className="icons" onClick={()=>setText(refresponseData.id)} /> }</h1> */}

                      <h4> {share? share:"Refer Your Friends,Earn Cashback"}</h4>
                      <div>
                        <ul className='d-flex gap-10 items-start p-0 m-0'>
                          <Link to="https://www.facebook.com/dialog/share?app_id=202127659076&display=popup&href=https%3A%2F%2Fwww.fiverr.com%2Fpe%2FEVp0wq&quote=Discover+the+fastest+way+to+hire+freelancers.+Join+me+on+Fiverr%2C+get+a+discount+off+your+first+order+and+I+will+get+a+special+discount+too%21">
                            <li className='h-[48px] w-[48px] border-[#000] item-center border d-flex justify-center align-center rounded-md d-flex align-center items-center'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none"><path d="M3.89058 23.8262V13.4808H0V9.36971H3.89058V6.13055C3.89058 2.61129 6.30507 0.695801 9.83043 0.695801C11.5196 0.695801 12.9703 0.808742 13.3913 0.858437V4.53581H10.9464C9.02898 4.53581 8.65869 5.34899 8.65869 6.53714V9.36971H12.9855L12.392 13.4808H8.65869V23.8262" fill="#1877F2"></path></svg>

                            </li>
                          </Link>

                          <Link to="https://twitter.com/intent/tweet?text=Discover+the+fastest+way+to+hire+freelancers.+Join+me+on+Fiverr%2C+get+a+discount+off+your+first+order+and+I+will+get+a+special+discount+too%21&url=https%3A%2F%2Fwww.fiverr.com%2Fpe%2FWPlZ2B&related=fiverr">
                            <li className='h-[48px] w-[48px] border-[#000] item-center border d-flex justify-center align-center rounded-md d-flex align-center items-center'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none"><path d="M21.21 4.78498C21.225 4.99497 21.225 5.20501 21.225 5.415C21.225 11.82 16.35 19.2 7.44001 19.2C4.69499 19.2 2.14501 18.405 0 17.025C0.390014 17.07 0.764976 17.085 1.17 17.085C3.43496 17.085 5.51999 16.32 7.185 15.015C5.05499 14.97 3.26999 13.575 2.65498 11.655C2.955 11.7 3.25498 11.73 3.57001 11.73C4.005 11.73 4.44003 11.67 4.845 11.565C2.62501 11.115 0.959961 9.16499 0.959961 6.80999V6.75001C1.60494 7.11002 2.355 7.33501 3.14994 7.36498C1.84494 6.49496 0.989972 5.00998 0.989972 3.32997C0.989972 2.42998 1.22993 1.60498 1.64995 0.884977C4.03496 3.82498 7.61998 5.74494 11.6399 5.95498C11.565 5.59498 11.5199 5.22001 11.5199 4.84501C11.5199 2.17498 13.68 0 16.3649 0C17.7599 0 19.0199 0.584998 19.9049 1.53C20.9999 1.32001 22.0499 0.914989 22.98 0.360003C22.6199 1.48503 21.8549 2.43003 20.8499 3.02999C21.825 2.92504 22.77 2.65498 23.6399 2.28002C22.98 3.23998 22.155 4.09494 21.21 4.78498Z" fill="#08A0E9"></path></svg>

                            </li>
                          </Link>


                          <Link to="https://wa.me?text=Discover+the+fastest+way+to+hire+freelancers.+Join+me+on+Fiverr%2C+get+a+discount+off+your+first+order+and+I+will+get+a+special+discount+too%21+https%3A%2F%2Fwww.fiverr.com%2Fpe%2F2moGV4">
                            <li className='h-[48px] w-[48px] border-[#000] item-center border d-flex justify-center align-center rounded-md d-flex align-center items-center'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M17.744 14.3502C17.4444 14.1912 15.9954 13.4171 15.7306 13.2995C15.4659 13.182 15.2569 13.1336 15.0409 13.424C14.8319 13.7143 14.2258 14.3641 14.0447 14.5576C13.8636 14.7512 13.6894 14.765 13.3898 14.606C13.0903 14.447 12.1358 14.0945 11.0282 13.03C10.1643 12.2074 9.6 11.2051 9.4328 10.9009C9.27257 10.5968 9.4328 10.4378 9.58607 10.2926C9.73933 10.1475 9.89956 9.95392 10.0598 9.78111C10.22 9.60829 10.2688 9.49078 10.3803 9.29032C10.4848 9.08986 10.443 8.91705 10.3733 8.76498C10.3036 8.6129 9.76023 7.12673 9.53033 6.51152C9.30044 5.90323 9.04964 5.99309 8.8685 5.98618C8.69434 5.97926 8.49231 5.9447 8.29028 5.9447C7.98374 5.93779 7.68418 6.06221 7.47518 6.29032C7.18955 6.57373 6.38142 7.27189 6.32569 8.73733C6.26996 10.2028 7.28708 11.6751 7.43338 11.8825C7.57968 12.0829 9.391 15.2696 12.4006 16.576C15.4102 17.8825 15.4311 17.4885 15.9814 17.4539C16.5318 17.4194 17.7858 16.7972 18.0644 16.1129C18.3431 15.4286 18.364 14.8272 18.2943 14.7028C18.2247 14.5783 18.0226 14.4954 17.73 14.3295L17.744 14.3502ZM12.2194 21.4217C10.3036 21.4217 8.42264 20.8618 6.82729 19.8111L3.05835 21.0138L4.28447 17.3986C3.06531 15.7396 2.41045 13.7419 2.41742 11.6889C2.41742 6.31797 6.81335 1.95622 12.2264 1.95622C17.6395 1.95622 22.0354 6.31797 22.0354 11.6889C22.0354 17.0599 17.6255 21.4217 12.2194 21.4217ZM12.2194 0C5.71263 0 0.43193 5.23272 0.43193 11.6889C0.43193 13.818 1.01016 15.9055 2.12482 17.7235L0 24L6.52075 21.9263C8.26241 22.8802 10.227 23.3848 12.2125 23.3848C18.7193 23.3848 23.993 18.1521 23.993 11.6959C23.993 5.23963 18.7263 0 12.2194 0Z" fill="#25D366"></path></svg>

                            </li>
                          </Link>

                          <Link to="https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.fiverr.com%2Fpe%2FyGY23b&source=Fiverr">
                            <li className='h-[48px] w-[48px] border-[#000] item-center border d-flex justify-center align-center rounded-md d-flex align-center items-center'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M0.394271 7.95447H5.36854V23.9572H0.394271V7.95447ZM2.88215 0C4.4717 0 5.76404 1.2931 5.76404 2.88564C5.76404 4.47819 4.4717 5.76678 2.88215 5.76678C1.29261 5.76678 -0.000488281 4.47744 -0.000488281 2.88564C-0.000488281 1.29385 1.28811 0 2.88215 0Z" fill="#2867B2"></path><path d="M8.48779 7.95459H13.2594V10.1438H13.3277C13.9912 8.88445 15.6137 7.55908 18.0333 7.55908C23.0699 7.55908 24.0005 10.8725 24.0005 15.1796V23.9566H19.0285V16.1747C19.0285 14.3188 18.9962 11.9307 16.443 11.9307C13.8898 11.9307 13.4606 13.9548 13.4606 16.0419V23.9566H8.48779V7.95459Z" fill="#2867B2"></path></svg>

                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
        </>
    )
}

export default Modal





