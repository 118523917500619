import React from "react";
import { Link } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import Modal from "../modal/Modal";
import { userContext } from "../../Context/UserContextProvider";
function CategoryCard(props) {
  const {
    sharemodel,
    mode,
    setSharemodel,
    shareModelcontent,
    setShareModelcontent,
    shareModelitemid,
    setShareModelitemid,
  } = React.useContext(userContext);

  const [spinner, setSpinner] = React.useState(false);
  let [responseData, setResponseData] = React.useState("");
  let [errorData, setErrorData] = React.useState("");

  // const usertoken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDcxODEwYWJhMzc0OGZmZDc4ZjAwN2IiLCJyb2xlIjoidXNlciIsImlhdCI6MTY4NTI1MTUzOSwiZXhwIjoxNjg3ODQzNTM5fQ.5V86CNEz3a_OEzpCMaAF4b5bW-vhigMqUi7a_LIugBQ';

  const usertoken = localStorage.getItem("user_token");

  const handlecategory = (categoryId) => {
    localStorage.setItem("categoryId", categoryId);
  };

  //   share model open author: Nitesh

  const handleShow = (cateName, courseId) => {
    console.log("clicked for open modalbox");
    setShareModelcontent(cateName);
    setSharemodel(true);
    setShareModelitemid(courseId);
  };

  return (
    <>
      <section
        className={`${mode === "dark" ? "order_dark_mode" : "my_order_card "}`}
      >
        <div className={`${props.width} mx-4 py-2 `}>
          <div className="relative">
            <div className=" xl:h-[13rem] object-cover flex justify-center items-center card_image_top">
              <Link
                to={"/Similar-Course?catrgory_id=" + props.detail?._id}
                onClick={() => handlecategory(props.detail?._id)}
              >
                <img
                  src={
                    props.detail.category_image
                      ? props.detail.category_image
                      : "../plceholdernav/placeholder-img.png"
                  }
                  className="sm:w-full order_img object-cover sm:h-full"
                  alt="category image"
                />
              </Link>
            </div>
            {/* <div className='absolute top-[-.4rem] left-2'>
                <div className=''>
                    <img src={require(`../../assets/images/${props.tag}.png`)} alt="" width={40} />
                </div>
            </div> */}
          </div>
          <div className="text-center">
            <div className="py-2 flex justify-center items-center">
              <p className="bg-[#d1f9cd] text-[#268a1d] px-2 my-0 py-1 rounded-xl w-fit">
                {props?.detail?.category_name}
              </p>
              <button
                className="py-0 ms-2 text-[1.2rem] text-[#565656] font-medium hover:text-[#b18cf5]"
                onClick={() =>
                  props.handleShow(
                    props?.detail?.category_name,
                    props.detail?._id,
                    "category"
                  )
                }
              >
                <FaShareAlt style={{ color: mode == "dark" && "white" }} />
              </button>
            </div>
            <div className="my-0">
              <p className="my-0 font-semibold">
                {" "}
                {props.detail.courses.length} Courses Available
              </p>
              <div className="flex" style={{ justifyContent: "center" }}>
                <p
                  style={{ color: mode === "dark" && "white" }}
                  className="my-0 text-[1.2rem] text-[#565656] font-medium"
                >
                  <small>
                    4.8{" "}
                    <span>
                      <i className="fa fa-star text-[#FFD12D]"></i>
                    </span>{" "}
                    (878 ratings)
                  </small>
                </p>
                {/* <button className='py-0 text-[1.2rem] text-[#565656] font-medium'></button> */}
              </div>
            </div>
            {/* <div className='py-1'>
            <p className='font-semibold'>Status: <span className='text-[orange] text-medium px-1'> In Progress</span></p>
        </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default CategoryCard;
