import React, { Fragment, useEffect, useRef, useState } from 'react'
import LoginNavbar from '../LoginNavbar/LoginNavbar'
import Footer from '../Footer/Footer'
import user_profile from '../../assets/images/profile/user-img.png'
import users_icons from '../../assets/images/users.png'
import history_star from '../../assets/images/history-star.png'
import points_icon from '../../assets/images/points-icon.png'
import refer_star from '../../assets/images/refer-star.png'
import axios from 'axios'
import ReactSwitch from 'react-switch'
import { BiCopy } from 'react-icons/bi'
import { TiTick } from 'react-icons/ti'
import { Link } from 'react-router-dom'

import { Dialog, Transition } from '@headlessui/react'

import Modal from '../modal/Modal'
import { userContext } from '../../Context/UserContextProvider'
import UseAxios from '../../util/UseAxios'
import MetaData from '../MetaTitle/MetaData'

// import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const ReferEarn = () => {
  const { capVal,mode, setMode,profiledata,setProfiledata,sharemodel, setSharemodel, } = React.useContext(userContext)
  // ========refer and history toggle button============
  const [checked, setChecked] = useState(true)
  const handleChange = (val) => {
    setChecked(val)
  }

  const usertoken = localStorage.getItem('user_token')
  const instance = UseAxios()

  // refer modal
  const [open, setOpen] = useState(false)
  const handleShow = (e) => setSharemodel(true)

  const cancelButtonRef = useRef(null)

  // const userdetail = JSON.parse(localStorage.getItem("user_detail"));

  // console.log(usertoken)

  const [spinner, setSpinner] = React.useState(false)
  let [responseData, setResponseData] = React.useState('')
  console.log(responseData);
  let [errorData, setErrorData] = React.useState('')
  const[color,setColor]=useState()
  const[backgroundColor,setBackground]=useState("#f6f4f4")  


  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)

      try {
        const response = await instance.get('/api/v1/auth/profile/')
        console.log(response.data)
        setProfiledata(response.data.user)
        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        setErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])

  let [refresponseData, setrefResponseData] = React.useState('')
  let [referrorData, setrefErrorData] = React.useState('')

  useEffect(()=>{
    console.log(mode);
  if(mode==="dark"){
    setColor('white')
    setBackground('black')
  }else{
    setColor('#7D7D7D')
    setBackground('#f6f4f4')
  }
  },[mode])


  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)

      try {

        const response = await instance.get('/api/v1/auth/referral/id')
        console.log(response.data)
        setrefResponseData(response.data)
        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        setrefErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])

  let [refpointresponseData, setrefpointResponseData] = React.useState('')
  let [refpoint, setrefpoint] = React.useState(0)

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)

      try {
        const response = await instance.get(
          '/api/v1/auth/referral/referred-user',
        )
        console.log(response.data)
        setrefpointResponseData(response.data.referred_users)
        setrefpoint(response.data.remaining_points)
        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        // setrefpointErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])


  // copy clipboad
  const [text, setText] = useState('');
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(text);
      alert('Copied to clipboard: ' + text);
    } catch (err) {
      console.error('Unable to copy to clipboard.', err);
    }
  };

  return (
    <>
         <MetaData title={"Navya | Refer and earn"} canonicalUrl={"https://navyaedu.com/refer-and-earn"}/>
      <LoginNavbar />
      <div className="w-[90%] m-auto py-10">
        <div className="pt-2">
          <h1 className="refer_heading">Refer And Earns</h1>
        </div>
        <div className="user_details_main_top_div">
          <div className="rafer_earn_main_box">
            <div className="user_details_main">
              <div>
                <img
                  src={profiledata.profile_image ? profiledata.profile_image : user_profile}
                  alt="profile"
                  className=" user_img  edit_user_img mx-auto object-cover rounded-full"
                />
              </div>
              <div className="user_details" >
                <h3 style={{color:mode=='dark'&& "white"}} >{profiledata?.name}</h3>
                <p>{profiledata?.email}</p>
                <p>{profiledata?.phone_no}</p>
                <p>{profiledata?.Myreferral_code}</p>
              </div>
            </div>
            <div className="earn_points_div">
              <button className="earn_points ">
                <img src={points_icon} className="img-fluid" />
                <span style={{color:mode=='dark'&& "white"}} > {refpoint} Points</span>
              </button>
            </div>
          </div>
          <div>
            <div className="login_with_row mt-5" >
              <p className="filter_heading"  style={{color:mode=='dark'&& "white"}}>
                Filter:
                <label style={{ marginLeft: '10px',color:mode=='dark'&& "white"}}>Refer & Earn </label>
                <ReactSwitch
                  checked={checked}
                  onChange={handleChange}
                  className="toggle_btn"
                />
                <label style={{color:"white !important"}}>History</label>
              </p>
            </div>
            <div>
              {checked ? (
                <div>
                  {/* =========History ========== */}
                  <div>
                    {Array.isArray(refpointresponseData) ? (
                      refpointresponseData.map((users,index) => 
                     { 
                      const maskEmail = (email) => {
                        const [username, domain] = email.split('@');
                        console.log(username)
                        console.log(domain)
                        const maskedUsername = username.substring(0, 4) + username.substring(3).replace(/./g, '*');
                        return maskedUsername + '@' + domain;
                      };
                      return(
                        <div key={index} className="refer_single_col" style={{background:mode=='dark'&& "#151515"}}>
                          <div className="refer_single_col_heading_side">
                            <div className="refer_single_col_heading_side_inner">
                              {/* <img src={history_star} alt="" className="" /> */}
                              <img src={`https://api.multiavatar.com/${index+5}.svg`} alt="" className="" />
                              <div>
                                <h2  style={{color:mode=='dark'&& "white"}}>{users?.referred_user?.name}</h2>
                                {/* <h3  style={{color:mode=='dark'&& "white"}}>{users?.referred_user?.email}</h3> */}
                                <h3  style={{color:mode=='dark'&& "white"}}>{maskEmail(users?.referred_user?.email)}</h3>
                                <h3>
                                  {users?.createdAt?.split('T')[0]}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      )})
                    ) : (
                      <p style={{color:mode=='dark'&& "white"}}>No Reffered Users available.</p>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {/* =========Refer ========== */}

                  <div className="refer_single_col"  style={{background:mode=='dark'&& "#151515"}}>
                    <div className="refer_single_col_heading_side">
                      <div className="refer_single_col_heading_side_inner">
                        <img src={users_icons} alt="" className="" />
                        <div>
                          <h2 style={{color:mode=='dark'&& "white"}}>Refer any 1 friend</h2>
                          <h3 style={{color:mode=='dark'&& "white"}}>+30 Points</h3>
                        </div>
                      </div>
                      <div className="">
                        <button
                          className="Invite_earn_green_btn btn_hover_white"
                          onClick={handleShow}
                        >
                          Invite & Earn
                        </button>

                      </div>
                    </div>
                  </div>

                  <div className="refer_single_col" style={{background:mode=='dark'&& "#151515"}}>
                    <div className="refer_single_col_heading_side">
                      <div className="refer_single_col_heading_side_inner">
                        <img src={users_icons} alt="" className="" />
                        <div>
                          <h2 style={{color:mode=='dark'&& "white"}}>Refer any 1 friend</h2>
                          <h3 style={{color:mode=='dark'&& "white"}}>+30 Points</h3>
                        </div>
                      </div>
                      <div>
                        <button
                          className="Invite_earn_green_btn btn_hover_white"
                          onClick={handleShow}
                        >
                          Invite & Earn
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="refer_single_col" style={{background:mode=='dark'&& "#151515"}}>
                    <div className="refer_single_col_heading_side">
                      <div className="refer_single_col_heading_side_inner">
                        <img src={users_icons} alt="" className="" />
                        <div>
                          <h2 style={{color:mode=='dark'&& "white"}}>Refer any 1 friend</h2>
                          <h3 style={{color:mode=='dark'&& "white"}}>+30 Points</h3>
                        </div>
                      </div>
                      <div>
                        <button
                          className="Invite_earn_green_btn btn_hover_white"
                          onClick={handleShow}
                        >
                          Invite & Earn
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ==============open model=========================== */}

     <Modal
      open={sharemodel}
      setOpen={setSharemodel}
      refId={refresponseData.id}
      handleCopyClick={handleCopyClick}
      setText={setText}

     />

      <Footer></Footer>
    </>
  )
}

export default ReferEarn
