import React, { Fragment, useState, useEffect } from "react";
import Answer from "./Answer";
import TestTarck from "./TestTarck";
import TestTrackCard from "./TestTrackCard";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import { Dialog, Transition } from "@headlessui/react";
import { BsCheckCircle } from "react-icons/bs";
import { Link, useBlocker, useNavigate } from "react-router-dom";
import TestDetails from "./TestDetails";
import SubjectCard from "./SubjectCard";
import QuestionCard from "./QuestionCard";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Dropdown from "react-bootstrap/Dropdown";
import { BsInfo } from "react-icons/bs";
import { HiArrowNarrowRight } from "react-icons/hi";
import { MdFullscreen } from "react-icons/md";
import parse from "html-react-parser";
import { userContext } from "../../Context/UserContextProvider";
import Testnavbar from '../LoginNavbar/Testnavbar'
import UseAxios from '../../util/UseAxios'
import MetaData from "../MetaTitle/MetaData";

function Test() {
  const {mode}=React.useContext(userContext)
  const instance = UseAxios()
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [countStatus0, setcountStatus0] = React.useState(0);
  const [countStatus1, setcountStatus1] = React.useState(0);
  const [countStatus2, setcountStatus2] = React.useState(0);
  const [countStatus20, setcountStatus20] = React.useState(0);
  const [countStatus, setcountStatus] = React.useState(0);
  const [countStatusNovisit, setcountStatusNovisit] = React.useState(0);
  const [timeLeft, setTimeLeft] = React.useState(0);
  // const toggleFullScreen = () => {
  //   if (!isFullScreen) {
  //   const element = document.documentElement;
  //   element.requestFullscreen();
  //   }
  //   setIsFullScreen(!isFullScreen);
  // };
  // //console.log(countStatus)

  const usertoken = localStorage.getItem("user_token");


  useEffect(()=>{
    localStorage.removeItem("queData");
  },[])

  function toggleFullScreen() {
    //console.log("click full screen button ");
    const element = document.body;
    const fullscreenElement =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullscreenElement ||
      document.msFullscreenElement;

    if (fullscreenElement) {
      // Exit full screen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // Enter full screen
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (typeof window.ActiveXObject !== "undefined") {
        var wscript = new window.ActiveXObject("WScript.Shell");
        if (wscript !== null) {
          wscript.SendKeys("{F11}");
        }
      }
    }
  }

  // =======================reload handle===========================================

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      //console.log("called");
      // Check some condition to determine if back navigation should be prevented
      const shouldPreventBackNavigation = true;

      if (shouldPreventBackNavigation) {
        //console.log("called isnide");
        const customMessage =
          "Are you sure you want to leave? Your changes may not be saved.";
        event.preventDefault();
        event.returnValue = customMessage; // Standard for most browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // =======================reload handle finished===========================================

  const navigate = useNavigate();
  // ==============================handle back button=======================================
  let [value, setValue] = React.useState("ffd");
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      value !== "" && currentLocation.pathname !== nextLocation.pathname
  );


  // ==============================handle back button End=======================================

  // right click disable  start test author : Nitesh
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  $(document.body).on("click", ".sidebarbtns", function () {
    $(".wrapper").toggleClass("is-shift-content  bggs");
    $(".sidebar").toggleClass("is-shift-sidebar");
  });

  //userprofile

  let [userresponseData, setuserResponseData] = React.useState("");
  let [usererrorData, setuserErrorData] = React.useState("");
  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true);

      try {
        const response = await instance.get("/api/v1/auth/profile");
        //console.log(response.data.user);
        setuserResponseData(response.data.user);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setuserErrorData(error);
      }
    };

    fetchData();
  }, [usertoken]);

  //sections

  const [spinner, setSpinner] = React.useState(false);
  const params = new URLSearchParams(window.location.search);
  const [quiz, setQuiz] = React.useState(params.get("quiz_id"));
  const [section, setSection] = React.useState(params.get("quiz_id"));
  const [sectionresponseData, setsectionResponseData] = React.useState([]);
  const [sectionerrorData, setsectionErrorData] = React.useState("");
  const [activeTab, setActiveTab] = useState(0);

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true);

      try {
        const response = await instance.get("/api/v1/quizzes/section/" + quiz);
        //console.table(response.data.sections);
        //console.log(response.data.sections);
        setsectionResponseData(response.data.sections);

        if (!isFullScreen) {
          const element = document.documentElement;
          element.requestFullscreen();
        }
        setIsFullScreen(!isFullScreen);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setsectionErrorData(error);
      }
    };

    fetchData();
  }, [usertoken, quiz]);

  let [responseData, setResponseData] = React.useState([]);
  let [errorData, setErrorData] = React.useState("");
  const [currentSection, setCurrentSection] = React.useState(null);
  const [sectionDataFetched, setSectionDataFetched] = React.useState({});
  // //console.log(responseData)
  useEffect(() => {
    if (Array.isArray(sectionresponseData) && sectionresponseData?.length > 0) {
      const firstSectionId = sectionresponseData[0]._id;
      fetchData(firstSectionId);
      setCurrentSection(firstSectionId);
    }
  }, [sectionresponseData]);

  const renderTabs = () => {
    if (!Array.isArray(sectionresponseData)) {
      return null; // or return an error message
    }

    return sectionresponseData.map((section, index) => (
      <div
        key={section._id}
        className={`border p-2 mx-2 ${
          index === activeTab
            ? "bg-[#775DA8] text-[white] rounded"
            : "bg-[transparent] border text-[black] p-2 rounded text-center mx-2 subject_btn_hover"
        }`}
        onClick={
          switchqwe === true ? () => handleSectionClick(index) : undefined
        }
      >
        {/* <p className="d-flex items-center">
          <span>{section.section_name}</span>
          <span className="ml-2">
            <AiOutlineInfoCircle />
          </span>
        </p> */}
        {index === activeTab ? (
          <Dropdown className="View_Instructions_btn subject_name_dropdown">
            <Dropdown.Toggle id="dropdown-basic">
              {parse(`${section.section_name.replaceAll("&lt;", "<")}`)}
              <span className="ml-2">
                <AiOutlineInfoCircle />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="instructuins_main">
              <div className="subject_drop_main">
                <p>
                  <div className="Answered_mark_reviw subject_num_box">
                    {countStatus20}
                  </div>
                  Answered And Marked For Review
                </p>
              </div>
              <div className="subject_drop_main">
                <p>
                  <div className="mark_reviw subject_num_box">
                    {countStatus2}
                  </div>
                  Marked For Review
                </p>
              </div>
              <div className="subject_drop_main">
                <p>
                  <div className="answer subject_num_box">{countStatus1}</div>
                  Answered
                </p>
              </div>
              <div className="subject_drop_main">
                <p>
                  <div className="not_answer subject_num_box">
                    {countStatus0}
                  </div>
                  Not Answered
                </p>
              </div>
              <div className="subject_drop_main">
                <p>
                  <div className="not_visit subject_num_box ">
                    {/* {countStatus} */}
                    {countStatusNovisit}
                  </div>
                  Not Visited
                </p>
              </div>
              {/* <div className="subject_drop_main">
                <p>
                  <div className="marked subject_num_box">1</div> Marked &
                  Answered Question will be considered for evaluation
                </p>
              </div> */}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <div className="bg-[transparent] text-[black] p-2 rounded text-center mx-2 ">
            <p className="d-flex items-center ">
              <span>{section.section_name}</span>
              <span className="ml-2">
                <AiOutlineInfoCircle />
              </span>
            </p>
          </div>
        )}
      </div>
    ));
  };

  const [quizNo, setQuizNo] = React.useState(0); // Initial quiz number

  const handleSectionClick = (tabIndex) => {
    console.log("click section switch ")
    //console.log(tabIndex);
    if (tabIndex !== activeTab) {
      setActiveTab(tabIndex);
      setCurrentSection(sectionresponseData[tabIndex]._id);
      const selectedSectionId = sectionresponseData[tabIndex]._id;

      if (!sectionDataFetched[selectedSectionId]) {
        fetchData(selectedSectionId);
        setSectionDataFetched((prevData) => ({
          ...prevData,
          [selectedSectionId]: true,
        }));

        //console.log(currentSection,sectionDataFetched,responseData,tdresponseData);

        if (tdresponseData.can_switch_sections === true) {
          console.log(" switch section if-------")
        } else {
          console.log(" switch section else -------")

          const durationInMinutes =
            tdresponseData?.quiz_sections[tabIndex].section_duration;
          setTimeLeft(durationInMinutes * 60);
        }

        if (sectionDataFetched[selectedSectionId]?.length < quizNo) {
          setQuizNo(
            parseInt(sectionDataFetched[selectedSectionId]?.length) - 1
          );
        }
      } else {
        // Use the saved response data for the section
        setResponseData(sectionDataFetched[selectedSectionId]);

        if (sectionDataFetched[selectedSectionId]?.length < quizNo) {
          setQuizNo(
            parseInt(sectionDataFetched[selectedSectionId]?.length) - 1
          );
        }
      }
    }

    const currentQuestionId = responseData[quizNo]._id;
    const selectedOptionIndex = selectedOptions[currentQuestionId];

    if (selectedOptionIndex !== undefined) {
      // If an option is selected, set status to 1 (green) in the JSON
      setQuestionStatus((prevStatus) => ({
        ...prevStatus,
        [currentQuestionId]: 1,
      }));
    } else {
      // If no option is selected, set status to 0 (white) in the JSON
      setQuestionStatus((prevStatus) => ({
        ...prevStatus,
        [currentQuestionId]: 0,
      }));
    }
  };
  const [visistedArray, setVisitedArray] = useState([]);
  // //console.log(visistedArray);

  const fetchData = async (sectionId) => {
    setSpinner(true);

    try {
      const response = await instance.get(
        "/api/v1/quizzes/question/" + sectionId
      );
      //console.log(response.data.questions);
      setResponseData(response.data.questions);
      // setVisitedArray(response.data.questions.map((x)=>x._id))
      setcountStatusNovisit(response.data.questions.length - 1);

      const ids = response.data.questions
        .map((obj, index) =>
          index === 0
            ? setQuestionStatus((prevStatus) => ({
                ...prevStatus,
                [obj._id]: 0,
              }))
            : obj._id
        ) // Exclude id of 0 index
        .filter((id) => id !== null); // Remove null values
      setVisitedArray(ids);

      const updatedSectionData = {
        ...sectionDataFetched,
        [sectionId]: response.data.questions,
      };
      setSectionDataFetched(updatedSectionData);
      setQuizNo(0);

      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      setErrorData(error);
    }
  };

  // const handleSectionClick = (sectionId) => {
  //   if (sectionId !== currentSection) {
  //     setCurrentSection(sectionId);
  //     fetchData(sectionId);
  //   }
  // };

  const [selectedmcqOptions, setSelectedMCQOptions] = useState([]); // State to store selected options
  const [selectedOptions, setSelectedOptions] = useState({}); // State to store selected options
  const [questionStatus, setQuestionStatus] = useState({}); // State to store question status (0, 1, or 2)
  const [questionDataJSON, setQuestionDataJSON] = useState({}); // State to store the JSON object
  const [questionDatasubmit, setQuestionsubmit] = useState(); // State to store the JSON object
  //console.log(questionStatus)
  // //console.log(selectedOptions)
  //console.log(questionDatasubmit)

  //author : Nitesh

  const [answerd_que, setAnswerd_que] = useState([]);
  const [not_answerd_que, setNot_answerd_que] = useState([]);
  const [not_answerd_que2, setNot_answerd_que2] = useState([]);
  const [marke_for_review, setMarke_for_review] = useState([]);
  const [answerd_marke_for_review, setAnswerd_marke_for_review] = useState([]);
//console.log(not_answerd_que2)
  useEffect(() => {
    setAnswerd_que(
      Object.keys(questionStatus).filter((x) => questionStatus[x] === 1)
    );
    setNot_answerd_que(
      // Object.keys(questionStatus).filter((x) => questionStatus[x] === 0)
      questionDatasubmit?.filter(item => item.optionId.length === 0 && item.user_answer === "").map(item => item.questionId)
    );
    setMarke_for_review(
      Object.keys(questionStatus).filter((x) => questionStatus[x] === 2)
    );
    setAnswerd_marke_for_review(
      Object.keys(questionStatus).filter((x) => questionStatus[x] === 20)
    );
    if(!responseData[quizNo]?._id in selectedOptions ){
      setQuestionStatus((prevStatus) => ({
        ...prevStatus,
        [responseData[quizNo]?._id]: 0,
      }));
    }
    setNot_answerd_que2(not_answerd_que?.filter(id => !marke_for_review.includes(id)))
  }, [questionStatus]);

  // //console.log(question_option_select_data)
  // let answerd_que = Object.keys(questionStatus).filter(x => questionStatus[x] === 1);
  // let not_answerd_que = Object.keys(questionStatus).filter(x => questionStatus[x] === 0);
  // let marke_for_review = Object.keys(questionStatus).filter(x => questionStatus[x] === 2);
  // let answerd_marke_for_review = Object.keys(questionStatus).filter(x => questionStatus[x] === 20);
  // //console.log(answerd_que)
  // //console.log(
  //   "unattemp --------- ",
  //   countStatus0 + marke_for_review.length
  // );
  // //console.log(
  //   "attemp ------- ",
  //   countStatus1 + answerd_marke_for_review.length
  // );

  const localtestdata = {
    section_details: {
      of_section: section,
      time_taken: timeLeft, // time second me ja rha hai
      attempted_questions: countStatus1 + answerd_marke_for_review.length,
      unattempted_questions: countStatus0 + marke_for_review.length,
      not_answered: not_answerd_que2,
      answered: answerd_que,
      marked_for_review: marke_for_review,
      answered_and_marked_for_review: answerd_marke_for_review,
      not_visited: visistedArray,
      question_option_selected: questionDatasubmit,
    },
  };

  // //console.log(localtestdata.section_details);
  // //console.log(
  //   "attemp question length============ ",
  //   localtestdata.section_details.attempted_questions
  // );
  // //console.log(
  //   "unattemp question length =========== ",
  //   localtestdata.section_details.unattempted_questions
  // );
  // let question_option_select_data = responseData.map(item => ({
  //   questionId: item._id,
  //   optionId: [],
  // }));

  let question_option_select_data = [];
  //console.log(question_option_select_data)
  const handleOptionSelect = (
    e,
    questionId,
    questionType,
    optionIndex,
    OptionId
  ) => {
    //  let datashowe =  JSON.parse(localStorage.getItem("queData")) ?? [];
    //  //console.log(datashowe)
    // //console.log(questionId);
    // //console.log(optionIndex);
    // //console.log(OptionId);
    // //console.log(questionType);
    // //console.log(responseData);
    // //console.log(e);
    // //console.log(selectedOptions);
    // //console.log(selectedmcqOptions);

    let inpevent = e.target.checked;
    //console.log(question_option_select_data);

    //save a question dummy array in local storage key id is queData,author : Nitesh
    if (!localStorage.getItem("queData")) {
      question_option_select_data = responseData.map((item) => ({
        questionId: item._id,
        questionType: item.question_type,
        optionId: [],
        user_answer: "",
      }));

    } else {
      let localdata = JSON.parse(localStorage.getItem("queData"));
      //console.log(localdata);
      //console.log(question_option_select_data);
      question_option_select_data = localdata;
    }
    const newdataIndex = question_option_select_data.findIndex(
      (item) => item.questionId === questionId
    );
    for (let v of question_option_select_data) {
      if (v.questionId === questionId) {
        let datashowe = JSON.parse(localStorage.getItem("queData")) ?? [];
        //console.log(datashowe);
      }
    }

    //scq question ke leye
    if (questionType === "scq") {
      //console.log("scq");

      //console.log(newdataIndex);

      // if (newdataIndex !== -1) {
      question_option_select_data[newdataIndex] = {
        ...question_option_select_data[newdataIndex],
        optionId: [OptionId],
      };
      // }
      //option ko checked showe krane ke leyeai setSelectedOptions me questionId ke saat option inde add keya
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [questionId]: optionIndex,
      }));
    }

    // mcq question ke leye
    if (questionType === "mcq") {
      //console.log("mcq");
      question_option_select_data[newdataIndex] = {
        ...question_option_select_data[newdataIndex],
        optionId:
          inpevent === true
            ? [...question_option_select_data[newdataIndex].optionId, OptionId]
            : question_option_select_data[newdataIndex].optionId.filter(
                (x) => x != OptionId
              ),
      };
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [questionId]:
          inpevent === true
            ? [...(prevSelectedOptions[questionId] || []), optionIndex]
            : selectedOptions[questionId].filter((x) => x != optionIndex),
      }));
      //console.log(selectedOptions);
    }

    //eassy ke leye

    if (questionType === "essay") {
      //console.log(e.target.value);
      //console.log("eassy");
      question_option_select_data[newdataIndex] = {
        ...question_option_select_data[newdataIndex],
        user_answer: e.target.value,
      };
    }

    // "bool" type question
    if (questionType === "bool") {
      //console.log("bool");
      question_option_select_data[newdataIndex] = {
        ...question_option_select_data[newdataIndex],
        optionId: [OptionId],
      };

      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [questionId]: optionIndex,
      }));
    }

    // Store the JSON string in local storage
    localStorage.setItem(
      "queData",
      JSON.stringify(question_option_select_data)
    );
    setQuestionsubmit(question_option_select_data);
  };

  const handleSaveAndNext = () => {
    if (responseData.length === quizNo) {
      //console.log("last question update ");
      return false;
    }
    VisitQuestion(responseData[quizNo + 1]?._id);
    const currentQuestionId = responseData[quizNo]?._id;
    const nextQuestionId = responseData[quizNo + 1]?._id;

    const selectedOptionIndex = selectedOptions[currentQuestionId];
    //console.log(currentQuestionId);
    //console.log(selectedOptionIndex);

    // no option is selected, set status to 0 in the object
    if(nextQuestionId in selectedOptions != true){
      setQuestionStatus((prevStatus) => ({
        ...prevStatus,
        [nextQuestionId]: 0,
      }));

    }
    
    //console.log(responseData[quizNo].question_type)

    if(responseData[quizNo].question_type === "essay" && questionDatasubmit){
//console.log(questionDatasubmit)
      //console.log("is this right ye hai bhar vala -------------------------------------------------")
      if (questionDatasubmit[quizNo].user_answer != '') {
        //console.log("is this right -------------------------------------------------")
        //  option is selected, set status to 1 in the JSON
        setQuestionStatus((prevStatus) => ({
          ...prevStatus,
          [currentQuestionId]: 1,
        }));
      } else {
        
        // no option is selected, set status to 0 in the JSON
        setQuestionStatus((prevStatus) => ({
          ...prevStatus,
          [currentQuestionId]: 0,
        }));
      }

    }else{

    if (selectedOptionIndex !== undefined) {
      //  option is selected, set status to 1 in the JSON
      setQuestionStatus((prevStatus) => ({
        ...prevStatus,
        [currentQuestionId]: 1,
      }));
    } else {
      // no option is selected, set status to 0 in the JSON
      setQuestionStatus((prevStatus) => ({
        ...prevStatus,
        [currentQuestionId]: 0,
      }));
    }
  }
    // Increment quiz number and update the state
    setQuizNo((prevQuizNo) => prevQuizNo + 1);
  };

  const handleClearResponse = () => {
    const currentQuestionId = responseData[quizNo]._id;

    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [currentQuestionId]: undefined,
    }));

    // Set status to 0 (white) in the JSON
    setQuestionStatus((prevStatus) => ({
      ...prevStatus,
      [currentQuestionId]: 0,
    }));
let jsonData1 = localStorage.getItem("queData")
let jsonData = JSON.parse(jsonData1)


//console.log(jsonData1)
//console.log(jsonData)

// Check if jsonData is an array
if (Array.isArray(jsonData)) {
  // Find the index of the question with the specified questionId
  const questionIndex = jsonData.findIndex(question => question.questionId === currentQuestionId);

  // Check if the question with the specified questionId exists
  if (questionIndex !== -1) {
    // Remove the entire optionId array for the specific question
    jsonData[questionIndex].optionId = [];
    jsonData[questionIndex].user_answer = '';

  }

  // Log the modified data
  //console.log(jsonData);
  localStorage.setItem("queData",JSON.stringify(jsonData));
} else {
  //console.error("jsonData is not an array.");
  //console.log(jsonData);
}




  };

  // Function to handle "Mark For Review" button click
  const handleMarkForReview = () => {
    if (responseData[quizNo + 1] && responseData[quizNo + 1]._id !== undefined) {
      VisitQuestion(responseData[quizNo + 1]._id);
  }
    // VisitQuestion(responseData[quizNo + 1]?._id);
    const currentQuestionId = responseData[quizNo]._id;
    const nextQuestionId = responseData[quizNo + 1]?._id;
    const selectedOptionIndex = selectedOptions[currentQuestionId];
    //console.log(currentQuestionId);
    //console.log(selectedOptionIndex);
    // setSelectedOptions((prevSelectedOptions) => ({
    //   ...prevSelectedOptions,
    //   [currentQuestionId]: undefined,
    // }));
    if(responseData[quizNo].question_type === "essay" && questionDatasubmit){
      if (questionDatasubmit[quizNo].user_answer != '') {
        setQuestionStatus((prevStatus) => ({
          ...prevStatus,
          [currentQuestionId]: 20,
        }));
      } else {
        // Set status to 2 (orange) in the JSON
        setQuestionStatus((prevStatus) => ({
          ...prevStatus,
          [currentQuestionId]: 2,
        }));
      }

    }
   else{
     if (selectedOptionIndex !== undefined) {
      setQuestionStatus((prevStatus) => ({
        ...prevStatus,
        [currentQuestionId]: 20,
      }));
    } else {
      // Set status to 2 (orange) in the JSON
      setQuestionStatus((prevStatus) => ({
        ...prevStatus,
        [currentQuestionId]: 2,
      }));
    }
  }

    // Increment quiz number and update the state
    if (responseData?.length == parseInt(quizNo) + 1) {
      setQuizNo((prevQuizNo) => prevQuizNo);
    } else {
      setQuizNo((prevQuizNo) => prevQuizNo + 1);
    }


     // no option is selected, set status to 0 in the object
if(responseData?.length <= quizNo){  
     if(nextQuestionId in selectedOptions != true){
      setQuestionStatus((prevStatus) => ({
        ...prevStatus,
        [nextQuestionId]: 0,
      }));

    }}
  };
  //optional 429 line check same work method different
  // function add_option_status(){
  //   const option_id_selectedinp = document.querySelectorAll('.radio_inp_put_status')
  //   for(let i=0;i<=option_id_selectedinp.length-1;i++){

  //     //console.log(option_id_selectedinp);
  //     //console.log(option_id_selectedinp[i].value);
  //     //console.log(option_id_selectedinp[i].attributes[2].value);
  //     let que_id = option_id_selectedinp[i].attributes[2].value;
  //     // //console.log(plan_id_selectedinp);

  //     if(option_id_selectedinp[i].checked === true){
  //       //console.log(option_id_selectedinp[i].checked+ '>>>>>id>>>>>>seee ---- ' + option_id_selectedinp[i].value);

  //       // purchases_plans.cart_id.push(option_id_selectedinp[i]?.attributes[1].value);

  //     }

  //   }
  // }

  const sendstatus = {
    is_quiz_live: false,
  };
  const sendquizstatus = async () => {
    const response = await instance.patch(
      `/api/v1/quizzes/quiz/quiz-attempt-details/${quiz}`,
      sendstatus
    );
    //console.log(response.data);
    toast.success(response.data.message);
  };

  const sendatasuccess = () => {
    setValue("");
    setSpinner(true)

    setTimeout(async () => {
      try {
        const res = await instance.post(
          `/api/v1/quizzes/report/${quiz}`,
          localtestdata
        );
        //console.log(res);
        //console.log(res.status);
        setSpinner(false)
        navigate(
          "/test-report?quiz_id=" +
            section +
            "&time=" +
            JSON.stringify(timeLeft) +
            "&json_qu_status=" +
            JSON.stringify(questionStatus) +
            "&json_ans=" +
            JSON.stringify(selectedOptions)
        );
        sendquizstatus();
        if (res.status === 201) {
          localStorage.removeItem("queData");
        }
      } catch (error) {
        //console.log(error);
        setSpinner(false)
        //console.log("error vala part cla ");
      }
    }, 1000);
  };

  const [Successopen, setSuccessOpen] = React.useState(false);
  const SuccesshandleShow = (e) => {
    //console.log("SuccesshandleShow function ");
    // handleSaveAndNext()

    const currentQuestionId = responseData[quizNo]._id;
    const selectedOptionIndex = selectedOptions[currentQuestionId];

    if (selectedOptionIndex !== undefined) {
      // If an option is selected, set status to 1 (green) in the JSON
      setQuestionStatus((prevStatus) => ({
        ...prevStatus,
        [currentQuestionId]: 1,
      }));
    } else {
      // If no option is selected, set status to 0 (white) in the JSON
      setQuestionStatus((prevStatus) => ({
        ...prevStatus,
        [currentQuestionId]: 0,
      }));
    }

    //console.log(questionStatus);
    // return false
    // sendatasuccess();
    return e.preventDefault(), setSuccessOpen(true);
  };

  const SuccesshandleClose = (e) => {
    return e.preventDefault(), setSuccessOpen(false);
  };
  const cancelButtonRef = React.useRef(null);

  //

  const totalQuiz = responseData?.length;
  // const quizNo = props.quiz_no;

  //not visit question ides
  function VisitQuestion(id,flag) {
    //console.log(id);

    // let currentId = responseData[quizNo]?._id
    let currentId = id;
    const filtered = visistedArray.filter(
      (x) => x != currentId && x != undefined
    );
    // const filterundefined =
    //console.log(filtered);
    setVisitedArray(filtered);
    setcountStatusNovisit(filtered.length);
    // object add data in selected is panding
    //console.log(questionStatus);
    //console.log(selectedOptions);
    const object_filter = Object.fromEntries(
      Object.entries(questionStatus).filter(([key, value]) => value === 0)
    );
    // //console.log(object_filter);
// Iterate through questionstatus keys
if(flag === "jump"){
for (const id in questionStatus) {
  if (questionStatus[id] === 0 && selectedOptions.hasOwnProperty(id)) {
      // Remove the key from selectedoption
      delete selectedOptions[id];
  }
}

}

    //console.log(selectedOptions);
    // const newselected = Object.fromEntries(
    //   Object.entries(selectedOptions)
    //   .filter(([id]) => !questionStatus[id] || questionStatus[id] !== 0)
    //   )
    //   //console.log(newselected);
    //   setSelectedOptions(newselected)

    //  not answsward click to
    if(flag === "jump"){
      if(id in questionStatus){
        //console.log("added already")
      }else{

        setQuestionStatus((prevStatus) => ({
          ...prevStatus,
          [currentId]: 0,
        }));

      }
      
    }
   

    // //console.log(quizNo)
    // let filrterData = responseData.
    // setResponseData((pre)=>(
    //   {...pre ,responseData.map((x))}
    // ))
    //  //console.log(responseData[quizNo]?._id)
    //  //console.log(responseData[quizNo])
    //  //console.log(visistedArray)
    //  //console.log(countStatusNovisit)
  }

  useEffect(() => {
    // Calculate the counts whenever the questionStatus state changes
    const calculateCounts = () => {
      let count0 = 0;
      let count1 = 0;
      let count2 = 0;
      let count20 = 0;
      let countUndefined = 0;
      // //console.log(questionStatus);
      // //console.log("first");
      for (const questionId in questionStatus) {
        const status = questionStatus[questionId];

        if (status === 0) {
          count0++;
        } else if (status === 1) {
          count1++;
        } else if (status === 2) {
          count2++;
        } else if (status === 20) {
          count20++;
        } else if (status === undefined) {
          countUndefined++;
        }
      }

      setcountStatus0(count0);
      setcountStatus1(count1);
      setcountStatus2(count2);
      setcountStatus20(count20);
      setcountStatus(countUndefined);
    };

    calculateCounts();
  }, [questionStatus]);

  const renderQuestionNumbers = () => {
    const questionNumbers = [];

    for (let i = 1; i <= totalQuiz; i++) {
      const currentQuestionId = responseData[i - 1]._id;
      const className =
        parseInt(quizNo) + 1 === i
          ? "d-flex items-center justify-center w-11 h-11 border-2 brorder-[white] rounded-full text-center font-semibold text-[white] text-xl  bg-[#775EA5] quest_hover"
          : "d-flex items-center justify-center w-11 h-11 align-middle border-2 brorder-[#775EA5] rounded-full text-center font-semibold text-[white] text-xl quest_hover " +
            (questionStatus[currentQuestionId] === 1
              ? " bg-[#66BE5E] text-white hover:bg-[#66BE5E] hover:text-white"
              : questionStatus[currentQuestionId] === 2
              ? " bg-[orange] text-white"
              : questionStatus[currentQuestionId] === 0
              ? " bg-[red]"
              : questionStatus[currentQuestionId] === 20
              ? " bg-[#000]"
              : questionStatus[currentQuestionId] === undefined
              ? ""
              : "");
      const classNameforp =
        parseInt(quizNo) + 1 === i
          ? "text-[white]"
          : questionStatus[currentQuestionId] !== undefined
          ? "text-white"
          : "text-[#7d7d7d]";
      questionNumbers.push(
        <div className={className} key={i} onClick={() => setQuizNo(i - 1)}>
          <h2
            className={"quiz_no_btn series_font " + classNameforp}
            onClick={() => VisitQuestion(responseData[i - 1]._id,"jump")}
          >
            {i}
          </h2>
        </div>
      );
    }

    return questionNumbers;
  };

  let [secresponseData, setsecResponseData] = React.useState("");
  let [secerrorData, setsecErrorData] = React.useState("");
  const [switchqwe, setSwitchqwe] = React.useState(0);

  // const params = new URLSearchParams(window.location.search)

  // const [section, setSection] = React.useState()
  // //console.log()
  // const usertoken = localStorage.getItem('user_token')
  let [tdresponseData, settdResponseData] = React.useState("");
  //console.log(tdresponseData)
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get(
          "/api/v1/quizzes/single-quiz/" + section
        );
        //console.log(response.data.quiz);
        settdResponseData(response.data.quiz);
        //console.log(response.data.quiz)
        setSwitchqwe(response.data.quiz.can_switch_sections);

        if (response.data.quiz.can_switch_sections === true) {
          const durationInMinutes = response.data.quiz.quiz_duration;
          setTimeLeft(durationInMinutes * 60);
        } else {
          const durationInMinutes =
            response.data.quiz.quiz_sections[activeTab].section_duration;
          setTimeLeft(durationInMinutes * 60);
        }
      } catch (error) {
        setsecErrorData(error);
      }
    };

    fetchData();
  }, [usertoken]);

  React.useEffect(() => {
    if (tdresponseData) {
      const timer = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft((prevTime) => prevTime - 1);
        } else {
          // Time has reached 0 minutes and 00 seconds

          if (localStorage.getItem("queData")) {
            sendatasuccess();
          } else {
            setSuccessOpen(true);
          }
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [tdresponseData, timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const [showAdditionalData, setShowAdditionalData] = useState(false);
  const changelang = (e) => {
    //console.log(showAdditionalData);
    setShowAdditionalData((prevValue) => !prevValue);
  };

  const abcdef = () => {
    // blocker.proceed()
    //console.log("hsdfsjdh");
    navigate("/myorder");
  };
  const options = ["A", "B", "C", "D", "E","F","G","H"];

  // const [selectedOption, setSelectedOption] = React.useState(null);

  // const handleOptionChange = (optionIndex) => {
  //   setSelectedOption(optionIndex);
  // };

  // const [selectedOptions, setSelectedOptions] = useState({});

  // Function to handle the selection of an option
  // const handleOptionSelect = (questionId, optionIndex) => {
  //   setSelectedOptions((prevSelectedOptions) => ({
  //     ...prevSelectedOptions,
  //     [questionId]: optionIndex,
  //   }));
  // };

  return (
    <div className="select-none">
      <MetaData title={"Navya | Test"} canonicalUrl={"https://navyaedu.com/startTest"}/>
      <Testnavbar />
      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}
      {/* <UnsavedChangesPrompt value={value} /> */}
      {/* {blocker.state === "blocked" ? (
        <div
          style={{position:"absolute", left:"5%", top:"3%"}}
         class="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
  <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span class="sr-only">Info</span>
  <div>
    <span class="font-medium">!!</span> Your Data will not Save
  </div>
</div>
      ) : null} */}
      {isFullScreen ? (
        <section className="test_main_section">
          <div class="wrapper min-h-[89.5vh]" >
            <div className="w-[90%] start_text_section_left_part m-auto flex justify-between flex-col lg:flex-row md:flex-column">
              <div className="lg:w-full">
                <div className="my-3 p-10 bg-[#b1b1b122] rounded-lg">
                  {/* <TestDetails  img={1}></TestDetails> */}
                  <div className="flex justify-between items-center test_details_main lg:flex-row md:flex-column test_name_timer_div">
                    <div className="flex items-center lg:flex-row md:flex-column">
                      <div className="">
                        <h1 className="font-bold text-2xl lg:text-3xl mr-5 series_font" style={{color:mode==="dark"&&"white"}}>
                          {parse(`${tdresponseData?.of_plan?.of_course_series?.series_name.replaceAll("&lt;", "<")}`)}
                        </h1>
                      </div>
                      {responseData[quizNo]?.question_title_hindi != '' && <div onClick={changelang}>
                        <img
                          src={require(`../../assets/images/MycourseTag/${1}.png`)}
                          alt=""
                          width={35}
                        />
                      </div>}
                      <div className="mx-3">
                        <Dropdown className="View_Instructions_btn">
                          <Dropdown.Toggle id="dropdown-basic">
                            <span className=" bg-[#775DA8] rounded-full p-0.5 mr-2">
                              <BsInfo
                                className="text-white "
                                style={{ fontSize: "20px" }}
                              />
                            </span>{" "}
                            <span className="text-[#775DA8]">
                              View Instructions?
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="instructuins_main">
                            <div className="instructuins series_font">
                              <h3>Instructions</h3>

                              <p className="">
                                <span>
                                  <HiArrowNarrowRight />
                                </span>
                                Exam : {parse(`${tdresponseData?.quiz_name?.replaceAll("&lt;", "<")}`)}
                               
                              </p>
                              <p className="">
                                <span>
                                  <HiArrowNarrowRight />
                                </span>
                                Total Time : {sectionresponseData[0]?.section_duration} min
                               
                              </p>
                              <p className="">
                                <span>
                                  <HiArrowNarrowRight />
                                </span>
                                Negative Marking : {responseData[0]?.question_negative_marking}
                               
                              </p>
                              <p className="">
                                <span>
                                  <HiArrowNarrowRight />
                                </span>
                                Mark Per Question : {responseData[0]?.question_marks}
                               
                              </p>
                              <p className="">
                                <span>
                                  <HiArrowNarrowRight />
                                </span>
                                Total No. Of Question : {sectionresponseData[0]?.quiz_section_questions?.length}
                               
                              </p>
                              <p className="">
                                <span>
                                  <HiArrowNarrowRight />
                                </span>
                                Total Marks : {sectionresponseData[0]?.quiz_section_questions?.length * responseData[0]?.question_marks}
                               
                              </p>
                              <p className="">
                                <span>
                                  <HiArrowNarrowRight />
                                </span>
                                Section : {sectionresponseData[0]?.section_name}
                               
                              </p>
                        
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="time_wid">
                      {/* { isFullScreen && */}
                      <div className="mr-4 flex justify-center items-center">
                        <abbr
                          title="F11"
                          onClick={() => toggleFullScreen()}
                          className=" mr-5 !cursor-pointer text-[grey] text-3xl me-5  hover:text-[#775DA8]"
                        >
                          <MdFullscreen color={mode=="dark"&&"white"} />
                        </abbr>
                      </div>
                      {/* } */}

                      <div>
                        <span className="test_timer series_font"  style={{color:mode==="dark"&&"white"}}>
                          <i className="fa fa-clock-o text-[red]"></i>{" "}
                          {formatTime(timeLeft)} left
                        </span>
                      </div>
                    </div>
                  </div>

                  <section className="subject_card" style={{background:mode==="dark"?"#151515":"white"}}>
                    <div className=" px-2 py-2 flex items-center my-10 rounded ">
                      {renderTabs()}
                    </div>
                  </section>

                  {/* <QuestionCard detail={responseData ? responseData[quizNo] : ''} quiz_no={quizNo} additionalData={showAdditionalData ? "swapLanguage" : null} ></QuestionCard> */}

                  <>
                    {responseData ? (
                      <div className="question_card_main select-none">
                        <div className="py-2">
                          <p className="md:text-lg xl:text-2xl question series_font">
                            <span className="font-bold text-[#775EA5] series_font" style={{color:mode==="dark"&&"white"}}>
                              Q{parseInt(quizNo) + 1}.
                            </span>

                            <span className="series_font select-none" style={{color:mode==="dark"&&"white"}}>
                              {showAdditionalData === true && responseData[quizNo].question_title_hindi != ''
                                ? responseData[quizNo]?.question_title_hindi
                                : parse(`${responseData[quizNo]?.question_title.replaceAll("&lt;", "<")}`)}
                            </span>
                            {responseData[quizNo]?.question_image && <img src={responseData[quizNo]?.question_image} width={200} height={200}></img>}
                          </p>
                        </div>
                        {/* <Options detail={responseData[quizNo]?.question_options} questionId={responseData[quizNo]?._id} additionalData={showAdditionalData}></Options> */}

                        <>
                          {responseData[quizNo]?.question_options?.length >
                          0 ? (
                            responseData[quizNo]?.question_options.map(
                              (option, index) => (
                                <div className="p-2" key={index}>
                                  <div className="options options_main">
                                    <div className="flex items-center">
                                      <div className="w-7 h-7 rounded-full border border-[#775EA5]">
                                        <p className="text-center" style={{color:mode==="dark"&&"white"}}>
                                          {options[index]}
                                        </p>
                                      </div>
                                      <div className="mx-2 text-[#5b5a5a]">
                                        {responseData[quizNo]?.question_type ===
                                        "mcq" ? (
                                          <label className="series_font checkbox-container">
                                            <input
                                            style={{color:mode==="dark"&&"white"}}
                                              id={index}
                                              className="checkbox_inp_put_status"
                                              type="checkbox"
                                              value={option?._id}
                                              que_id={
                                                option?.of_quiz_section_question
                                              }
                                              name={
                                                "option_" +
                                                responseData[quizNo]?._id +
                                                "_" +
                                                index
                                              }
                                              // checked={
                                              //   selectedOptions[
                                              //     responseData[quizNo]?._id
                                              //   ] === index
                                              // }
                                              checked={selectedOptions[
                                                responseData[quizNo]?._id
                                              ]?.includes(index)}
                                              onChange={(e) =>
                                                handleOptionSelect(
                                                  e,
                                                  responseData[quizNo]?._id,
                                                  responseData[quizNo]
                                                    ?.question_type,
                                                  index,
                                                  option._id
                                                )
                                              }
                                            />

                                            <span
                                              className="option_content series_font checkmark"
                                              style={{ marginLeft: "15px" ,color:mode==="dark"&&"white"}}
                                             
                                            >
                                              {showAdditionalData === true && option.option_title_hindi != ''
                                                ? option.option_title_hindi
                                                : parse(`${option.option_title.replaceAll("&lt;", "<")}`)}
                                            </span>
                                            {option?.option_image != '' && <img src={option?.option_image} width={200} height={200}></img>}
                                           
                                          </label>
                                        ) : (
                                          <label className="series_font radio-container">
                                            <input
                                             style={{color:mode==="dark"&&"white"}}
                                              className="radio_inp_put_status"
                                              type="radio"
                                              value={option?._id}
                                              que_id={
                                                option?.of_quiz_section_question
                                              }
                                              name={
                                                "option_" +
                                                responseData[quizNo]?._id +
                                                "_" +
                                                index
                                              }
                                              checked={
                                                selectedOptions[
                                                  responseData[quizNo]?._id
                                                ] === index
                                              }
                                              onChange={(e) =>
                                                handleOptionSelect(
                                                  e,
                                                  responseData[quizNo]?._id,
                                                  responseData[quizNo]
                                                    ?.question_type,
                                                  index,
                                                  option._id
                                                )
                                              }
                                            />

                                            {/* <input type="radio" name="option" /> */}
                                            <span
                                              className="option_content series_font checkmark"
                                              style={{ marginLeft: "15px" , color:mode==="dark"&&"white" }}
                                            >
                                              {showAdditionalData === true && option.option_title_hindi != ''
                                                ? option.option_title_hindi
                                                : parse(`${option.option_title.replaceAll("&lt;", "<")}`)}
                                              
                                            </span>
                                              {option?.option_image != '' && <img src={option?.option_image} width={200} height={200}></img>}
                                          </label>
                                        )}

                                        {/* <p className='series_font'><span></span><span className='option_content series_font'>{option.option_title}</span></p> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          ) : (
                            <>
                              {responseData[quizNo]?.question_type ===
                              "essay" ? (
                                <>
                                  <div className="p-2" key={0}>
                                    <div className="options options_main">
                                      <div className="">
                                        <div className="mx-2 text-[#5b5a5a]">
                                          <label className="series_font radio-container w-full">
                                            <textarea
                                              id="myTextarea"
                                              placeholder={
                                                showAdditionalData === true
                                                  ? "यहां निबंध लिखें...."
                                                  : "Write Essay Here...."
                                              }
                                              value={localStorage.getItem(`queData`) && responseData[quizNo]._id === JSON.parse(localStorage.getItem(`queData`))[quizNo]?.questionId && JSON.parse(localStorage.getItem(`queData`))[quizNo]?.user_answer != "" ? JSON.parse(localStorage.getItem(`queData`))[quizNo].user_answer : ""}
                                              type="text"
                                              rows="5"
                                              // cols="100"
                                              // name={
                                              //   'option_' +
                                              //   responseData[quizNo]?._id
                                              // }
                                              name={
                                                "option_" +
                                                (responseData[quizNo]?._id ||
                                                  "")
                                              }
                                              className="w-full"
                                              onChange={(e) =>
                                                handleOptionSelect(
                                                  e,
                                                  responseData[quizNo]?._id,
                                                  responseData[quizNo]
                                                    ?.question_type,
                                                  3
                                                )
                                              }
                                              // onChange={(event) => handleOptionSelect(responseData[quizNo]?._id, event.target.value)}
                                            ></textarea>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {responseData[quizNo]?.question_type ===
                              "bool" ? (
                                <>
                                  <div className="p-2" key={0}>
                                    <div className="options options_main">
                                      <div className="flex items-center">
                                        <div className="mx-2 text-[#5b5a5a]">
                                          <label className="series_font radio-container">
                                            <input
                                              type="radio"
                                              value="0"
                                              placeholder="True"
                                              name={
                                                "option_" +
                                                responseData[quizNo]?._id
                                              }
                                              checked={
                                                selectedOptions[
                                                  responseData[quizNo]?._id
                                                ] === 0
                                              }
                                              onChange={(e) =>
                                                handleOptionSelect(
                                                  e,
                                                  responseData[quizNo]?._id,
                                                  responseData[quizNo]
                                                    ?.question_type,
                                                  responseData[quizNo]
                                                    ?.question_options?._id,
                                                  0
                                                )
                                              }
                                            />

                                            <span
                                              className="option_content series_font checkmark"
                                              style={{ marginLeft: "15px" }}
                                            >
                                              {showAdditionalData === true
                                                ? "सत्य"
                                                : "True"}
                                            </span>

                                          </label>
                                        </div>
                                        <div className="mx-2 text-[#5b5a5a]">
                                          <label className="series_font radio-container">
                                            <input
                                              type="radio"
                                              value="1"
                                              placeholder="False"
                                              name={
                                                "option_" +
                                                responseData[quizNo]?._id
                                              }
                                              checked={
                                                selectedOptions[
                                                  responseData[quizNo]?._id
                                                ] === 1
                                              }
                                              onChange={(e) =>
                                                handleOptionSelect(
                                                  e,
                                                  responseData[quizNo]?._id,
                                                  responseData[quizNo]
                                                    ?.question_type,
                                                  responseData[quizNo]
                                                    ?.question_options?._id,
                                                  1
                                                )
                                              }
                                            />
                                            <span
                                              className="option_content series_font checkmark"
                                              style={{ marginLeft: "15px" }}
                                            >
                                              {showAdditionalData === true
                                                ? "असत्य"
                                                : "False"}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      </div>
                    ) : (
                      <button className="submit_button">
                        Submit Your Course
                      </button>
                    )}
                  </>
                </div>
                {/* <Answer></Answer> */}
                <div>
                  <div className="flex justify-between items-center flex-col lg:flex-row md:flex-column">
                    <div className="flex md:basis-1/2 w-full flex-col lg:flex-row md:flex-column">
                      <div className="  ">
                        <button
                          className="my-2 md:my-1 w-full border border-[orange] text-center text-[orange] rounded p-3 md:mx-2 series_font hover_border_yellow"
                          onClick={handleMarkForReview}
                        >
                          Mark For Review & Next
                        </button>
                      </div>
                      <div className="mx-2">
                        <button
                          className="my-2 md:my-1 w-full border border-[grey] text-[grey]  text-center rounded p-3 md:mx-2 series_font hover_border_dark"
                          onClick={handleClearResponse}
                        >
                          Clear Response
                        </button>
                      </div>
                    </div>

                    <div className="my-2 md:my-1 Save_Next_btn">
                      {/* <button
                        className="my-2 md:my-1 w-full bg-[#66BE5E] p-3 px-5 text-center text-[white] basis-1/4 rounded series_font btn_hover"
                        onClick={
                          responseData?.length === parseInt(quizNo) + 1
                            ? sectionresponseData?.length !== activeTab + 1
                              ? () => handleSectionClick(activeTab + 1)
                              : SuccesshandleShow
                            : handleSaveAndNext
                        }
                      >
                        {responseData?.length === parseInt(quizNo) + 1
                          ? sectionresponseData?.length !== activeTab + 1
                            ? "Switch"
                            : "Submit"
                          : "Save & Next"}
                      </button> */}
                      <button
  className="my-2 md:my-1 w-full bg-[#66BE5E] p-3 px-5 text-center text-[white] basis-1/4 rounded series_font btn_hover"
  onClick={
    // Check if the response data length matches the quiz number + 1
    responseData?.length === parseInt(quizNo) + 1
      ? // If so, check if the section response data length does not match the active tab + 1
        sectionresponseData?.length !== activeTab + 1
        ? // If both conditions are true, switch to the next section
          () => handleSectionClick(activeTab + 1)
        : // If the first condition is true but the second is false, show success
          SuccesshandleShow
      : // If the first condition is false, save and move to the next item
        handleSaveAndNext
  }
>
  {
    // Determine the button label based on the same conditions
    responseData?.length === parseInt(quizNo) + 1
      ? sectionresponseData?.length !== activeTab + 1
        ? "Switch" // If both conditions are true
        : "Submit" // If the first condition is true but the second is false
      : "Save & Next" // If the first condition is false
  }
</button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sidebar">
              <div className=" px-0 py-3 ml-4">
                <section className="testtrack_main">
                  <div className="testtrack_main_inner rounded-xl">
                    <div className="flex items-center">
                      <div>
                        {/* <img src={require(`../../assets/images/profile/${profile?profile:''}.png`)} className='rounded-full' alt="" /> */}
                      </div>
                      <div className="px-3">
                        <p className="name series_font">
                          {userresponseData.name}
                        </p>
                        <p className="email series_font">
                          {" "}
                          <small className="series_font">
                            {userresponseData.email}
                          </small>
                        </p>
                      </div>
                    </div>
                    <div className="py-1">
                      <p className=" General_Instructions series_font">
                        General Instructions :
                      </p>
                      <div>
                        <div className="flex items-center">
                          <div className="bg-[#000] w-7 h-7 text-center justify-center items-center inline-flex rounded-full text-white mr-1 series_font">
                            {countStatus20}
                          </div>
                          <div className="question_report series_font">
                            Answered And Marked For Review
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between my-2">
                        <div>
                          <div className="flex items-center">
                            <div className="bg-[orange] w-7 h-7 text-center justify-center items-center inline-flex rounded-full text-white mr-1 series_font">
                              {/* {countStatus2} */}
                              {marke_for_review.length}
                            </div>
                            <div className="question_report series_font">
                              Marked For Review
                            </div>
                          </div>
                        </div>

                        <div className="flex items-center">
                          <div className="bg-[#7ee57e] w-7 h-7 justify-center items-center inline-flex text-center rounded-full text-white mr-1 series_font">
                            {countStatus1}
                          </div>
                          <div className="question_report series_font">
                            Answered
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between my-2">
                        <div>
                          <div className="flex items-center">
                            <div className="bg-[red] w-7 h-7 text-center rounded-full justify-center items-center inline-flex text-white mr-1 series_font">
                              {countStatus0}
                              {/* {not_answerd_que?.length} */}
                            </div>
                            <div className="question_report series_font">
                              Not Answered
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="bg-[transparent] justify-center items-center inline-flex w-7 h-7 text-center border rounded-full text-black mr-1 series_font">
                            {countStatusNovisit}
                          </div>
                          <div className="question_report series_font">
                            Not Visited
                          </div>
                        </div>
                      </div>
                      {/* <div className="my-2 ">
                        <div className="bg-[#775EA5] w-7 h-7 text-center  rounded-full text-white mr-1 series_font justify-center items-center inline-flex ">
                         
                          1
                        </div>
                        <div className="inline question_report series_font">
                          Marked & Answered Question will be considered for
                          evaluation
                        </div>
                      </div> */}
                    </div>
                    <div>
                      <div className="bg-[#f5f5f5]  rounded-xl my-2 TrackQuestionNumbers_main">
                        <div>
                          <p className="series_font">Question Numbers:</p>
                        </div>
                        <div className="grid grid-cols-4 gap-2  TrackQuestionNumbers">
                          {renderQuestionNumbers()}
                        </div>
                      </div>
                    </div>
                    {switchqwe === true ? (
                      <div className=" m-auto text-center">
                        <button
                          className="text-white bg-[#775EA5] w-1/2 m-auto text-center p-2 rounded my-2"
                          //  onClick={SuccesshandleShow} >Submit</button>
                          onClick={
                            sectionresponseData?.length !== activeTab + 1
                              ? () => handleSectionClick(activeTab + 1)
                              : SuccesshandleShow
                          }
                        >
                          {sectionresponseData?.length !== activeTab + 1
                            ? "Switch"
                            : "Submit"}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      <Transition.Root show={Successopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-9999"
          initialFocus={cancelButtonRef}
          onClose={setSuccessOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-9999 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                style={{background:mode==="dark"?"#151515":"white"}}
                 className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div 
                  style={{background:mode==="dark"?"#151515":"white"}}
                  className=" px-4 pb-4 pt-5 sm:p-6 sm:pb-4  ">
                    <div className="success_edit_profile">
                      <BsCheckCircle
                        className="icons"
                        style={{ fontSize: "50px" }}
                      />
                      <h4 
                       style={{color:mode==="dark"&&"white"}}
                      className="series_font">
                        Your test has Submitted Successfully!{" "}
                      </h4>
                      <p
                      style={{color:mode==="dark"&&"white"}}
                       className="series_font">
                        Wait a moment while we are generating your result
                      </p>
                    </div>

                    <div className="Personal_Info_div ">
                      <div>
                        <div className="pt-5">
                          <div className="text-center">
                            <button className="   " onClick={sendatasuccess}>
                              <button
                                // onClick={abcdef}
                                className="text-white bg-[#66BE5E] py-3 px-5 mt-7 rounded text-white font-semibold w-[100%] m-auto series_font btn_hover"
                              >
                                View Result
                              </button>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default Test;
