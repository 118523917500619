import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Logo from '../../assets/images/logo.png'
import white from '../../assets/images/logo-white.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AiFillStar } from 'react-icons/ai'
import { Rating } from 'react-simple-star-rating'
import Pdf from 'react-to-pdf'
// import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import Weakchart from './Testreport/Weakchart'
import back_btn from '../../assets/images/back-btn.png'
import { HiArrowNarrowRight } from 'react-icons/hi'
import XYZreportChart from './Testreport/XYZreportChart'
import Sectionchart from './Testreport/Sectionchart'
import PieChartReport from './Testreport/PieChartReport'
import PieChartnotfill from './Testreport/PieChartnotfill'
import { useContext } from 'react'
import { userContext } from '../../Context/UserContextProvider'
import UseAxios from '../../util/UseAxios'
import NoCourses from '../new/NoCourses'
import ReactHtmlParser from 'html-react-parser';
import { toast } from "react-toastify";
import axios from 'axios'
import MetaData from '../MetaTitle/MetaData'
const RADIAN = Math.PI / 180


const TestReport = () => {
  const instance = UseAxios()
  const { showetest, setShoweTest, mode } = useContext(userContext)
  let [responseReport, setResponseReport] = React.useState([]);
  console.log(responseReport)
  let [showetable, setShowetable] = React.useState(5);
  let Navigate = useNavigate()
  // let [showetest,setShoweTest] = React.useState(localStorage.getItem("quizId"))
  // rating

  const { userId } = useParams()

  const [rating, setRating] = useState(0)
  const [spinner, setSpinner] = React.useState(false)

  window.onpopstate = function (event) {

    // window.location.href = "/";
    Navigate("/")


  };

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate)

    // other logic
  }

  const [Successopen, setSuccessOpen] = useState(false)
  const SuccesshandleShow = (e) => {
    return e.preventDefault(), setSuccessOpen(true)
  }

  const SuccesshandleClose = (e) => {
    return e.preventDefault(), setSuccessOpen(false)
  }
  // const cancelButtonRef = useRef(null)
  // const ref = React.createRef()

  const params = new URLSearchParams(window.location.search)

  const [quiz, setQuiz] = React.useState(params.get('quiz_id'))

  const [resultans, setResultans] = React.useState({})

  let [repsecresponseData, setrepsecResponseData] = React.useState('');
  let [repsecerrorData, setrepsecErrorData] = React.useState('');
  console.log(repsecresponseData)

  if (resultans.length > 0) {

    const quizReportData = {
      section_detail: resultans.map((result, index) => ({
        of_section: result.sectionId,
        time_taken: 0, // Fill with actual time taken for the section
        attempted_questions: result.questions.answered,
        unattempted_questions: result.questions.not_answered,
        correct_questions: result.questions.answered_right,
        incorrect_questions: result.questions.answered_wrong,
        marks_obtained: 0, // Fill with actual marks obtained for the section
        question_option_selected: {}, // Fill with the user's selected options for each question
      })),
    };




  }



  // user test report author : Nitesh 
  const sendstatus = {
    is_quiz_live: false,
  };
  const sendquizstatus = async () => {
    const response = await instance.patch(
      `/api/v1/quizzes/quiz/quiz-attempt-details/${quiz}`,
      sendstatus
    );
    console.log(response.data);
    // toast.success(response.data.message);
  };



  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)
      try {


        const response = await axios.get(`https://backend.navyaedu.com/api/v1/quizzes/reports/${userId}`);
        setSpinner(false)
        console.log(response.data.data);
        setResponseReport(response.data.data)
        localStorage.setItem("quizId", response.data.data[0].reportId)
        setShoweTest(response.data.data[0].reportId)

      } catch (error) {
        console.log(error)
        setSpinner(false)
      }
    };

    fetchData();
  }, []);

  console.log(responseReport)
  const [testid, setTestid] = useState('')

  function handleChange(e) {
    setTestid(e.target.value)

  }

  function clikTestshowe(testid) {
    console.log(testid)

    setShoweTest(testid)
  }



  let filterdata = responseReport?.find(item => item?.reportId === showetest)
  console.log(filterdata?.topicScore)
  console.log(filterdata)


  function viewMoreLess(flag) {
    if (flag === "more") {

      setShowetable(showetable + 10)
    }
    if (flag === "less") {

      setShowetable(5)
    }
  }



  return (
    <>
      <MetaData title={"Navya | Report"} canonicalUrl={"https://navyaedu.com/test-report"} />

      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass={`${mode === "dark" ? "loaderdark" : "loader"}`}

        />
      )}

      {responseReport.length
        ?
        <section className='select-none'>

          <section className=" only_logo_top_section "
            style={{
              paddingTop: 0
            }}
          >

            {/* <div className="mb-4">
              <div className="report_back_btn">
                <Link to="/startTest">
                  {' '}
                  <img src={back_btn} alt="back" className="img-fluid" /> Back
                </Link>
              </div>
            </div> */}
            <div className="help_feedback_div">
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="test_card_dark_blue" style={{ background: mode == "dark" ? "black" : "#604396", color: "white" }}>
                  <div className="test_name_rate_div">

                    <h4
                      style={{ color: "white" }}
                    > {ReactHtmlParser(filterdata?.testName?.replaceAll('&lt;', '<'))}</h4>
                    <button
                      className=" !hidden RateOurCourse "
                    // onClick={SuccesshandleShow}
                    >
                      <AiFillStar className="icons" /> Rate Our Course
                    </button>
                  </div>
                  <div className="Congratulations_div">
                    <div className="Congratulations_side_content">
                      <h5 style={{ color: "white" }} >
                        Congratulations! <br />

                        <h3 style={{ color: "white" }} className='mb-0 inline uppercase'>{ReactHtmlParser(filterdata?.username?.replaceAll('&lt;', '<'))}</h3>, you have scored
                        {/* <span className={responseaccData > 36 ? 'span_passed' : 'span_failed'}> {resultans?.length > 0 ? responseaccData : 0}% </span> */}

                        <h5 style={{ color: "white" }} className='mb-0 inline uppercase '> {filterdata?.scored_Marks}<span className=''>% </span></h5>
                        in the
                        <h5 style={{ color: "white" }} className='mb-0 inline uppercase'> {ReactHtmlParser(filterdata?.testName?.replaceAll('&lt;', '<'))} </h5>
                        test.
                      </h5>

                    </div>
                    <div></div>
                  </div>
                  <div className='test_name_rate_name'>
                    <h1 style={{ color: "white" }}>Taken Tests:</h1>
                    {/* <span style={{ color: mode === "dark" && "white" }}>I Couldn’t Understand. <a href="/setting?page=raiseticket">Need Help?</a></span> */}
                  </div>
                  <div className='All_test_list flex gap-3 mt-1' style={{
                    backgroundColor: "white"
                  }}>
                    {
                      responseReport.map((userreport, index) => {
                        return (
                          <div className='pt-2 pb-1' onClick={() => clikTestshowe(userreport?.reportId)}>
                            <div className='rounded-full'
                              style={{
                                backgroundColor: "#604396",
                                maxWidth: '150px',
                                cursor: "pointer",
                                transition: "all 0.3s ease-in-out",
                                height: '50px',
                                maxHeight: "55px",
                                padding: "5px 8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}>
                              <p style={{ color: "white", fontSize: '13px', textAlign: "center", marginTop: "5px" }} >{ReactHtmlParser(userreport?.testName?.replaceAll('&lt;', '<'))}</p>
                            </div>
                            {/* <a className='mt-2 inline-block'>View Report_1</a> */}
                          </div>
                        )
                      })
                    }



                  </div>
                  <div className='all_text_table overflow-scroll'>
                    <table class="table table-bordered mt-8">
                      <thead>
                        <tr>
                          <th style={{ color: mode === "dark" ? "white" : "white" }} scope="col" className='text-center'>Test</th>
                          <th style={{ color: mode === "dark" ? "white" : "white" }} scope="col" className='text-center'>Attempted Ques</th>
                          <th style={{ color: mode === "dark" ? "white" : "white" }} scope="col" className='text-center'>Unattempted Ques</th>
                          <th style={{ color: mode === "dark" ? "white" : "white" }} scope="col" className='text-center'>Not visited</th>
                          <th style={{ color: mode === "dark" ? "white" : "white" }} scope="col" className='text-center'>Correct Ans</th>
                          <th style={{ color: mode === "dark" ? "white" : "white" }} scope="col" className='text-center'>Incorrect Ans </th>
                          <th style={{ color: mode === "dark" ? "white" : "white" }} scope="col" className='text-center'>Obtained Marks </th>
                          <th style={{ color: mode === "dark" ? "white" : "white" }} scope="col" className='text-center'>Accuracy in % </th>
                          {/* <th style={{ color: mode === "dark" ? "white":"white" }} scope="col" className='text-center'>Weak Section </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          responseReport.slice(0, showetable).map((userreport, index) => {
                            return (
                              <tr key={index}>
                                <td style={{ color: mode === "dark" ? "white" : "white" }} >{ReactHtmlParser(userreport?.testName?.replaceAll('&lt;', '<'))}</td>
                                <td style={{ color: mode === "dark" ? "white" : "white" }} >{userreport?.attempted_question}</td>
                                <td style={{ color: mode === "dark" ? "white" : "white" }} >{userreport?.unAttempted_question}</td>
                                <td style={{ color: mode === "dark" ? "white" : "white" }} >{userreport.not_visited.length}</td>
                                <td style={{ color: mode === "dark" ? "white" : "white" }} >{userreport?.correct_answer}</td>
                                <td style={{ color: mode === "dark" ? "white" : "white" }} >{userreport?.incorrect_answer}</td>
                                <td style={{ color: mode === "dark" ? "white" : "white" }} >{userreport?.obtained_Marks}</td>
                                <td style={{ color: mode === "dark" ? "white" : "white" }} >{userreport?.accuracy}%</td>
                                {/* <td style={{ color: mode === "dark" ? "white":"white" }} className='flex justify-center'>{userreport?.weakSection?.map((x, i) => {
                                  return (

                                    <p>{`${x}${i !== userreport.weakSection.length - 1 ? ">" : ""}`}</p>

                                  )

                                })}</td> */}

                              </tr>
                            )
                          })
                        }

                      </tbody>
                    </table>
                    {responseReport.length < showetable ?
                      <div onClick={() => viewMoreLess('less')}>
                        <a>View Less</a>
                      </div>
                      : <div onClick={() => viewMoreLess('more')}>
                        <a>View More</a>
                      </div>}
                  </div>
                  <XYZreportChart mode={mode} report_data={responseReport} />

                  {/* All test Chart  :Bar chart */}
                  <div className="row">
                    <div className="col-lg-12">

                      <div className="" style={{ background: mode == "dark" ? "black" : "#604396", color: mode === "dark" && "white" }}>
                        <h2 className="report_other_headings" style={{ color: mode === "dark" ? "white" : "white" }}>Progress Bar</h2>
                        <div style={{ width: '100%', overflow: "scroll" }}>
                          <Sectionchart mode={mode} report_data={responseReport} />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <section className="test_main_sections only_logo_top_section">

              <div className="row">
                <div className="col-lg-12">
                  <div className="test_card_dark_blue" style={{ background: mode == "dark" ? "black" : "#604396", color: mode === "dark" && "white" }}>
                    <div className='flex items-center justify-between'>
                      <h2 style={{ color: mode === "dark" ? "white" : "white" }} className="report_other_headings text-[#52bb49]">Test Analysis:</h2>
                      <select
                        style={{ background: mode == "dark" ? "black" : "red", color: mode === "dark" && "white" }}
                        className="form-select form-select_two" onChange={handleChange}>
                        {/* <option value="" selected disabled >Select Test Type</option> */}
                        {
                          responseReport.map((userreport, index) => {
                            return (
                              <option style={{ background: mode == "dark" ? "black" : "white", color: mode === "dark" ? "white" : "white" }} key={index} value={userreport?.reportId} >{ReactHtmlParser(userreport?.testName?.replaceAll('&lt;', '<'))}</option>

                            )
                          })
                        }


                      </select>
                    </div>

                    <div className="w-full xl:flex mb-5 px-8">
                      <div className="w-full py-12 border text-center">
                        <PieChartReport report_data={responseReport} test_id={testid} />
                        <div className='xl:flex items-center justify-center'>
                          <div className='flex items-center mx-2'><p className=' text-white  text-whitebg-[#5B9BD5] w-3 h-3 m-0 me-1'></p>Attempted Ques</div>
                          <div className='flex items-center mx-2'><p className=' text-white bg-[#ED7D31] w-3 h-3 m-0 me-1'></p>Unattempted Ques</div>
                        </div>

                      </div>
                      <div className="w-full py-12 border  text-center">
                        <PieChartnotfill report_data={responseReport} test_id={testid} />
                        <div className='xl:flex items-center justify-center'>
                          <div className='flex items-center mx-2'><p className='text-white  bg-[#A5A5A5] w-3 h-3 m-0 me-1'></p>CorrectAnswers</div>
                          <div className='flex items-center mx-2'><p className='text-white  bg-[#FFC000] w-3 h-3 m-0 me-1'></p>IncorrectAnswers</div>
                        </div>

                      </div>
                    </div>







                    <div className='pt-8 pb-12'>
                      <h2 style={{ color: mode === "dark" ? "white" : "white" }} className="report_other_headings text-[#52bb49]">Analysis Weak Topics.</h2>
                      <Weakchart report_data={filterdata?.topicScore} />

                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="test_report_marks_detail_div">
                          <p>
                            <span>
                              <HiArrowNarrowRight />

                            </span>
                            {/* You Attempted {answeredset} Questions out of {answeredset + notansweredset} Questions */}
                            You Attempted {filterdata?.attempted_question} Questions out of {filterdata?.total_Questions} Questions
                          </p>
                          <p>
                            <span>
                              <HiArrowNarrowRight />
                            </span>
                            {/* You Attempted {answeredsetright} Right Answer And {notansweredsetwrong} Wrong Answer */}
                            You Attempted {filterdata?.correct_answer} Right Answer And {filterdata?.incorrect_answer} Wrong Answer
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='grafh_report_content'>
                      <div>
                        <p style={{ color: mode === "dark" ? "white" : "white" }}>{ReactHtmlParser(filterdata?.username?.replaceAll('&lt;', '<'))}, you scored {filterdata?.scored_Marks}% marks out of  {filterdata?.maximum_Marks} on the first attempt of &nbsp;
                          {ReactHtmlParser(filterdata?.testName?.replaceAll('&lt;', '<'))} on {filterdata?.test_attempted_date?.split('T')[0]}</p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>





              <div className="row">
                <div className="col-lg-12">

                  <div className="test_card_dark_blue test_card_right_ques_scroll" style={{ background: mode == "dark" ? "black" : "#604396", color: mode === "dark" && "white" }}>
                    <h2 style={{ color: mode === "dark" && "white" }} className="report_other_headings">
                      Correct Answers of the <h2 className='text-[white] inline'> {ReactHtmlParser(filterdata?.testName?.replaceAll('&lt;', '<'))}</h2>
                    </h2>

                    <div className='h-[52vh] overflow-scroll'>
                      {
                        filterdata?.questionsData?.map((que_solution, index) => {
                          return (
                            <div key={index} className="single_question_box">

                              <div className="report_answer">
                                <span style={{ color: mode === "dark" && "white" }} className='me-2 ms-2 font-extrabold'>{index + 1}. </span>
                                {/* <span className="report_correct_ans bg-[#775EA5]">A</span> */}
                                <p style={{ color: mode === "dark" && "white" }} className='!text-[#52bb49] m-0'>
                                  {que_solution?.questionTitle}
                                </p>
                              </div>
                              <div className="my-2 xl:flex ">
                                <div className="bg-[#52bb49] px-2  rounded-xl text-white text-center !mb-0">solution :</div>
                                <p style={{ color: mode === "dark" && "white" }} className='text-center text-[white] mb-1 ms-2'>
                                  {que_solution?.correctOptionTitle}
                                </p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>



                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 weal_links_col">

                  <div
                    style={{ background: mode == "dark" ? "#151515" : "#604396", color: mode === "dark" ? "white" : 'white' }}
                    className="test_card_dark_blue test_card_right_ques_scroll">
                    <h2 style={{ color: mode === "dark" ? "white" : "white" }} className="report_other_headings  ">Your Weak Points:

                    </h2>
                    <h3 style={{ color: mode === "dark" ? "white" : "white" }}>
                      Sorry! You must work smartly on the following topics.
                    </h3>
                    <ul>
                      {/* {filterdata?.weakSection?.map((x, i) => {
                          return (

                            <li style={{ color:mode==="dark"&&"white"}}>{x}</li>

                          )

                        })} */}
                      <li style={{ color: mode === "dark" ? "white" : "white" }} className='flex justify-left'>
                        {filterdata?.weakSection?.map((x, i) => {
                          return (

                            <p>{`${x}${i !== filterdata.weakSection.length - 1 ? ">" : ""}`}</p>

                          )

                        })}</li>

                    </ul>
                  </div>
                </div>
              </div>
              <div className="help_feedback_div px-4">


              </div>
              <div className="Download_Test_Report_div xl:flex items-center  justify-center">
                {/* <Pdf targetRef={ref} filename="code-example.pdf">
        {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
      </Pdf> */}
              </div>
            </section>

          </section>
        </section>
        :
        <section className='select-none'>

          <div className='h-[100vh] flex justify-center items-center'>
            <NoCourses stat="Report" />
          </div>
        </section>
      }


    </>
  )
}

export default TestReport;