import React from 'react'
import { Link } from 'react-router-dom'
import { FaShareAlt } from 'react-icons/fa'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { userContext } from '../../Context/UserContextProvider'
import Modal from '../modal/Modal'
function CategoryCard(props) {
  const [spinner, setSpinner] = React.useState(false)
  let [responseData, setResponseData] = React.useState('')
  let [errorData, setErrorData] = React.useState('')

  const {mode}=props

// console.log(props)

  const usertoken = localStorage.getItem('user_token')

  const handleCourse = (courseId) => {
    localStorage.setItem('courseId', courseId)
  }
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  //share model open authore:Nitesh
 
  return (
    <>
      <section className={`${mode==="dark" ?"order_dark_mode":"my_order_card "} lg:w-[270px] mb-4 mx-auto`}>
        <div className={`${props.width} px-3 pt-3`}>
          <div className="relative">
            <div>
              <Link
                to={`/course/${props.detail?._id}`}
                onClick={() => handleCourse(props.detail?._id)}
              >
                <img
                  src={props.detail.course_image ? props.detail?.course_image : '../plceholdernav/placeholder-img.png'}
                  className="lg:w-[18rem] xl:w-[18rem] lg:h-[12rem] object-contain w-full"
                  alt=""
                />
              </Link>
            </div>
            {/* <div className="absolute top-[-.4rem] left-2">
              <div className="">
                <img
                  src={require(`../../assets/images/${props.tag}.png`)}
                  alt=""
                  width={40}
                />
              </div>
            </div> */}
          </div>
          <div className="py-1 flex justify-center items-center">
            <p style={{color:mode==="dark"&&"white"}} className="bg-[#d1f9cd] text-[#268a1d] px-2 my-0 py-1 rounded-xl w-fit">
              {/* {props?.detail?.of_course_category?.category_name} */}
              {props?.detail?.category?.category_name}
            </p>
            <button className="py-0 ms-2 text-[1.2rem] text-[#565656] font-medium hover:text-[#b18cf5]" onClick={() => props.handleShow(props.detail.course_title, props.detail._id,"course")}>
                              <FaShareAlt color={mode=="dark"&&"white"} />
                            </button>
          </div>
          <div className="!pb-0 text-center">
            <p style={{color:mode==="dark"&&"white"}} className="my-0 font-semibold"> {props.detail.course_title} </p>
            <div className="flex" style={{ justifyContent: 'center' }}>
              <h5
              style={{color:mode==="dark"&&"white"}}
               className="py-0 my-0 text-[1.2rem] text-[#565656] font-medium">
                {props.detail.course_subtitle}
              </h5>
              {/* <button className='py-0 text-[1.2rem] text-[#565656] font-medium'></button> */}
              {/* <button className="py-0 text-[1.2rem] text-[#565656] font-medium">
                <FaShareAlt />
              </button> */}
            </div>
          </div>
          <div className="pb-1 text-center">
            <p
            style={{color:mode==="dark"&&"white"}}
             className="font-semibold">
              Status:{' '}
              <span className="text-[orange] text-medium px-1">
                {' '}
                In Progress
              </span>
            </p>
          </div>
        </div>
      </section>
  
    </>
  )
}

export default CategoryCard
