// import React, { useState } from 'react'
// import axios from 'axios'
// import Carousel from 'react-multi-carousel'
// import NoCourses from '../new/NoCourses'
// import { useEffect } from 'react'
// import { Link } from 'react-router-dom'
// import { FaShareAlt } from 'react-icons/fa';
// import Modal from '../modal/Modal'
// import { userContext } from '../../Context/UserContextProvider'
// import ReactHtmlParser from 'html-react-parser';
// import { Audio } from 'react-loader-spinner'
// import UseAxios from '../../util/UseAxios'
// import CategoryCard from '../Category/CategoryCard'
// function TrendingCategory({mode}) {
//   const usertoken = localStorage.getItem('user_token')
//   const instance = UseAxios()
//   const handlecategory = (categoryId) => {
//     localStorage.setItem('categoryId', categoryId);
//   }

//   const { sharemodel, setSharemodel,
//     sharemodelType, setSharemodelType,
//     shareModelcontent, setShareModelcontent,
//     shareModelitemid, setShareModelitemid } = React.useContext(userContext)

//   const handleShow = (cateName, courseId) => {
//     console.log('clicked for open modalbox');
//     setShareModelcontent(cateName)
//     setSharemodelType("category")
//     setSharemodel(true)
//     setShareModelitemid(courseId)
//   }

//   const responsive = {
//     superLargeDesktop: {
//       // the naming can be any, depends on you.
//       breakpoint: { max: 4000, min: 3000 },
//       items: 5,
//     },
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 4,
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 3,
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1,
//     },
//   }


//   let [catresponseData, setcatResponseData] = React.useState([])
//   let [caterrorData, setcatErrorData] = React.useState('')


//   // const handleCopyClick = async () => {
//   //   try {
//   //     await navigator.clipboard.writeText(text);
//   //     alert('Copied to clipboard: ' + text);
//   //   } catch (err) {
//   //     console.error('Unable to copy to clipboard.', err);
//   //   }
//   // };

//   React.useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await instance.get('/api/v1/courses/category')
//         console.log(response.data)
//         setcatResponseData(response.data.categories)
//       } catch (error) {
//         setcatErrorData(error)
//       }
//     }

//     fetchData()
//   }, [usertoken])
//   return (
//     <section className="popular_cources_main_section  TrendingCourse_main_section ">

//       <div className="container">
//         <div className="row">
//           <div className="col-lg-12 pb-2">
//             <h1 
//             style={{color:mode==="dark"&&"white"}}
//             className="popular_cources_main_section_heading mb-0">
//               Trending
//               <span>Categories</span>
//             </h1>
//           </div>
//         </div>
//         {/* <div className="flex flex-wrap flex-col md:flex-row sm:flex-col  "></div> */}
//         <Carousel 
//         responsive={responsive}
      
        
//         >
//           {Array.isArray(catresponseData) && catresponseData.length > 0 ? (
//             catresponseData.map((category, index) => (
//               <div key={index} className='lg:w-[270px] h-[auto] mr-8' >

//                 <div className='relative'>
//                   <div className=' xl:h-[17rem] object-cover flex justify-center items-center card_image_top'>
//                     <Link to={'/Similar-Course?catrgory_id=' + category?._id}
//                       onClick={() => handlecategory(category?._id)}
//                     >
//                       <img src={category.category_image === '' || category.category_image === undefined || category.category_image === null ? './plceholdernav/placeholder-img.png' : category.category_image} className='sm:w-full order_img object-cover sm:h-full' alt="not find" />
//                     </Link>
//                   </div>

//                 </div>
//                 <div className='card_text_bottom mt-2'>
//                   <div className='py-2 flex justify-center items-center'>
//                     <Link to={'/Similar-Course?catrgory_id=' + category?._id}
//                       onClick={() => handlecategory(category?._id)}
//                     >
//                       <p className='bg-[#d1f9cd] text-[#268a1d] px-2 my-0 py-1 rounded-xl w-fit category_name_1'>
//                         {/* {category.category_name} */}
//                         {RegExp('&lt;').test(category.category_name) ? ReactHtmlParser(`${category.category_name.replaceAll('&lt;', '<')}`) : category.category_name}
//                       </p>

//                     </Link>
//                     <button className="py-0 ms-2 text-[1.2rem] text-[#565656] font-medium hover:text-[#b18cf5]" onClick={() => handleShow(category.category_name, category?._id)}>
//                       <FaShareAlt  style={{color:mode==="dark"&&"white"}} />
//                     </button>
//                   </div>
//                   <div className='my-0'>
//                     <p className='my-0 font-semibold'> {category.courses.length}  Courses Available</p>
//                     <div className='flex' style={{ justifyContent: "center" }}>

//                       <p className='my-0 text-[1.2rem] text-[#565656] font-medium'><small>4.8 <span><i className='fa fa-star text-[#FFD12D]'></i></span> (878 ratings)</small></p>
//                       {/* <button className='py-0 text-[1.2rem] text-[#565656] font-medium'></button> */}

//                     </div>
//                   </div>
//                   {/* <div className='py-1'>
//             <p className='font-semibold'>Status: <span className='text-[orange] text-medium px-1'> In Progress</span></p>
//         </div> */}
//                 </div>
//               </div>

//             ))
//           ) : (
//             <Audio
//               height="80"
//               width="80"
//               radius="9"
//               color="green"
//               ariaLabel="loading"
//               background="transparent"
//               wrapperClass="content_loading"
//             />
//           )}
//         </Carousel>
//         <Modal
//           open={sharemodel}
//           setOpen={setSharemodel}
//           content={shareModelcontent}
//           share={'Share with your Friends'}
//           courseId={shareModelitemid}
//           typeShare={sharemodelType}
//         // handleCopyClick={handleCopyClick}
//         // setText={setText}
//         />
//       </div>
//     </section>
//   )
// }

// export default TrendingCategory

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaShareAlt } from 'react-icons/fa';
import Modal from '../modal/Modal';
import { userContext } from '../../Context/UserContextProvider';
import ReactHtmlParser from 'html-react-parser';
import { Audio } from 'react-loader-spinner';
import UseAxios from '../../util/UseAxios';

function TrendingCategory({ mode }) {
  const usertoken = localStorage.getItem('user_token');
  const instance = UseAxios();

  const handlecategory = (categoryId) => {
    localStorage.setItem('categoryId', categoryId);
  };

  const { sharemodel, setSharemodel, sharemodelType, setSharemodelType, shareModelcontent, setShareModelcontent, shareModelitemid, setShareModelitemid } = React.useContext(userContext);

  const handleShow = (cateName, courseId) => {
    setShareModelcontent(cateName);
    setSharemodelType("category");
    setSharemodel(true);
    setShareModelitemid(courseId);
  };

  let [catresponseData, setcatResponseData] = React.useState([]);
  let [caterrorData, setcatErrorData] = React.useState('');
  const [itemsPerSlide, setItemsPerSlide] = useState(4); // Default to 4 items

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get('/api/v1/courses/category');
        setcatResponseData(response.data.categories);
      } catch (error) {
        setcatErrorData(error);
      }
    };
    fetchData();
  }, [usertoken]);

  const getItemsPerSlide = () => {
    const width = window.innerWidth;
    if (width >= 1024) return 4; // For large desktop, show 4 items
    if (width >= 768) return 3; // For desktop, show 3 items
    if (width >= 464) return 2; // For tablet, show 2 items
    return 1; // For mobile, show 1 item
  };

  useEffect(() => {
    const handleResize = () => {
      setItemsPerSlide(getItemsPerSlide());
    };
    
    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial items per slide based on window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Group categories into chunks based on items per slide
  const groupedData = [];
  for (let i = 0; i < catresponseData.length; i += itemsPerSlide) {
    groupedData.push(catresponseData.slice(i, i + itemsPerSlide));
  }

  return (
    <section className="popular_cources_main_section TrendingCourse_main_section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 pb-2">
            <h1 style={{ color: mode === "dark" && "white" }} className="popular_cources_main_section_heading mb-0">
              Trending
              <span>Categories</span>
            </h1>
          </div>
        </div>

        {/* Bootstrap Carousel */}
        <div id="categoryCarousel" className="carousel slide" data-bs-ride="carousel">
          {/* Carousel Items */}
          <div className="carousel-inner p-3">
            {groupedData.length > 0 ? (
              groupedData.map((group, groupIndex) => (
                <div key={groupIndex} className={`carousel-item ${groupIndex === 0 ? 'active' : ''}`}>
                  <div className="row">
                    {group.map((category, index) => (
                                <div key={index} className={`col-${12 / itemsPerSlide}`}>
                        
                          <div className="card-body">
                            <div className="relative">
                              <div className="xl:h-[17rem] object-cover flex justify-center items-center card_image_top">
                                <Link to={`/Similar-Course?catrgory_id=${category?._id}`} onClick={() => handlecategory(category?._id)}>
                                  <img
                                    src={category.category_image === '' || category.category_image === undefined || category.category_image === null
                                      ? './plceholdernav/placeholder-img.png'
                                      : category.category_image}
                                    className="sm:w-full order_img object-cover sm:h-full"
                                    alt="not find"
                                  />
                                </Link>
                              </div>
                            </div>
                            <div className="card_text_bottom mt-2">
                              <div className="py-2 flex justify-center items-center">
                                <Link to={`/Similar-Course?catrgory_id=${category?._id}`} onClick={() => handlecategory(category?._id)}>
                                  <p className="bg-[#d1f9cd] text-[#268a1d] px-2 my-0 py-1 rounded-xl w-fit category_name_1">
                                    {RegExp('&lt;').test(category.category_name) ? ReactHtmlParser(`${category.category_name.replaceAll('&lt;', '<')}`) : category.category_name}
                                  </p>
                                </Link>
                                <button className="py-0 ms-2 text-[1.2rem] text-[#565656] font-medium hover:text-[#b18cf5]" onClick={() => handleShow(category.category_name, category?._id)}>
                                  <FaShareAlt style={{ color: mode === "dark" && "white" }} />
                                </button>
                              </div>
                              <div className="my-0">
                                <p className="my-0 font-semibold">{category.courses.length} Courses Available</p>
                                <div className="flex" style={{ justifyContent: "center" }}>
                                  <p className="my-0 text-[1.2rem] text-[#565656] font-medium">
                                    <small>4.8 <span><i className="fa fa-star text-[#FFD12D]"></i></span> (878 ratings)</small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <Audio height="80"
               width="80"
              radius="9" 
              color="green"
              ariaLabel="loading"
              background="transparent"
              wrapperClass="content_loading" />
            )}
          </div>

          {/* Carousel Controls */}
          <button className="carousel-control-prev" type="button" data-bs-target="#categoryCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#categoryCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <Modal
          open={sharemodel}
          setOpen={setSharemodel}
          content={shareModelcontent}
          share={'Share with your Friends'}
          courseId={shareModelitemid}
          typeShare={sharemodelType}
        />
      </div>
    </section>
  );
}

export default TrendingCategory;


