import React, { useState } from 'react'
import { Link,useNavigate,useHistory } from 'react-router-dom'
import NavyaImg from '../../assets/images/loginPageSigeImg.png'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Audio } from 'react-loader-spinner'
import ReactSwitch from 'react-switch'
import back_img from '../../assets/images/back.png'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signOut,
  
} from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import InputAdornment from '@mui/material/InputAdornment'
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import IconButton from '@mui/material/IconButton'
import ReCaptcha from '../recaptcha/ReCaptcha'
import { userContext } from '../../Context/UserContextProvider'
import Capnotverify from '../recaptcha/Capnotverify'
import Cookies from 'js-cookie'
import CountryList from '../../util/country.json'
import UseAxios from '../../util/UseAxios'
import MetaData from '../MetaTitle/MetaData'
import firebaseConfig from '../../util/firebaseConfig'

// Add this code outside your component (e.g., at the top of the file)



const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)

function StartPage() {
  const {capVal,setCapval,access_token ,setAccess_token ,mode}=React.useContext(userContext)
  //   password show hide
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const googleAuthProvider = new GoogleAuthProvider()
  const facebookAuthProvider = new FacebookAuthProvider()

  // const instance = axios.create({
  //   baseURL: 'https://backend.navyaedu.com/',
  //   headers: {
  //     'content-type': 'application/json'
  //   },
  // })
  const instance = UseAxios()
  // ========Email mobile number toggle button============
  const [checked, setChecked] = useState(true)
  const handleChange = (val) => {
    setChecked(val)
    localStorage.clear()
  }
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  let [countryMobile, setcountryMobile] = React.useState('')
  const [password, setPassword] = useState('')
  let [responseData, setResponseData] = React.useState('')
  let [errorData, setErrorData] = React.useState('')
  const [spinner, setSpinner] = React.useState(false)

  const handleCountryChange = (e) => {
    setcountryMobile(e.target.value);
  };

  let body_json

  if (checked) {
    body_json = {
      country_code: countryMobile,
      phone_no: phone,
      password: password,
    }
  } else {
    body_json = {
      email: email,
      password: password,
    }
  }

  
  let [responsecountryData, setcountryResponseData] = React.useState(CountryList)



  const navigate = useNavigate()

  const handleLoginWithSoghjycialAccount = async (e, provider) => {
    e.preventDefault()
    try {
      let res
      if (provider === 'google') {
        res = await signInWithPopup(auth, googleAuthProvider)
        // await signOut(auth);
        let bodyData = res.user.providerData[0]
        const updatedBodyData = Object.fromEntries(
          Object.entries(bodyData).map(([key, value]) => [key, value === null ? "" : value])
      );
      console.log(updatedBodyData)
      
        console.log(bodyData)
        await signOut(auth)
        // console.log(res.user.uid);
        setSpinner(true)
        const response = await instance
          .post('/api/v1/auth/social/login', updatedBodyData)
          .then((response) => {
            setSpinner(false)
            Cookies.set("user_token", response.data.token)
            Cookies.set('user_level', response.data.role)
            localStorage.setItem('user_token', response.data.token)
            localStorage.setItem('user_level', response.data.role)
            navigate('/myorder')
          })

      } else if (provider === 'facebook') {
        res = await signInWithPopup(auth, facebookAuthProvider)
        let bodyData = res.user.providerData[0]
        // await signOut(auth)
        setSpinner(true)
        const response = await instance
          .post('/api/v1/auth/social/login', bodyData)
          .then((response) => {
            setSpinner(false)
            console.log(response)
            Cookies.set("user_token", response.data.token)
            Cookies.set('user_level', response.data.role)
            localStorage.setItem('user_token', response.data.token)
            localStorage.setItem('user_level', response.data.role)
            navigate('/myorder')
          })
      }
    } catch (error) {
      setSpinner(false)

      console.log(error)
      toast.error(error.response.data.msg)
    }
  }

  const handleLoginWithSocialAccount = async (e, provider) => {
    e.preventDefault();
    try {
      let res;
      if (provider === 'google') {
        res = await signInWithPopup(auth, googleAuthProvider);
        // await signOut(auth);
        let bodyData = res.user.providerData[0]
        for (const key in bodyData) {
          if (bodyData.hasOwnProperty(key) && bodyData[key] === null) {
            bodyData[key] = ''; // Replace null with an empty string
          }
        }
        // console.log()
      
        const response = await instance
          // .post('https://backend.navyaedu.com/api/v1/auth/social/link',bodyData);
          .post('/api/v1/auth/socialAccountAuth',bodyData);

            let token =response.data.token 
            console.log(response.data);
            setAccess_token(token)
            localStorage.setItem('user_token', token)
            localStorage.setItem('user_level', response.data.role)
            Cookies.set("user_token", token)
            Cookies.set('user_level', response.data.role)
            // window.location.reload()
            navigate('/myorder')
      } 
      else if (provider === 'facebook') {
        console.log("facebook singup")
        
        res = await signInWithPopup(auth, facebookAuthProvider);
        console.log(res)

        let bodyData = res.user.providerData[0]
        for (const key in bodyData) {
          if (bodyData.hasOwnProperty(key) && bodyData[key] === null) {
            bodyData[key] = ""; // Replace null with an empty string
          }
        }

        // await signOut(auth);
        console.log(bodyData)
        const response = await instance
          .post(
            '/api/v1/auth/socialAccountAuth',bodyData);
            console.log(response)
            let token =response.data.token 
            setAccess_token(token)
            localStorage.setItem('user_token', token)
            localStorage.setItem('user_level', response.data.role)
            Cookies.set("user_token", token)
            Cookies.set('user_level', response.data.role)
            navigate('/myorder')
           
 
      }
    } catch (error) {
      console.log(error.message);
      console.log(error.code);
      if (error.code === 'auth/account-exists-with-different-credential') {
        toast.error('An account with this email already exists using a different authentication method.');
      }
      
      // toast.error("Invalid Credentials! Please try again.")
    }
  };



  

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log(capVal)

    setSpinner(true)
   if(capVal == null){  
    Capnotverify("Please Verify Captcha")
    setSpinner(false)
    return
   }

 
   

    instance
      .post('/api/v1/auth/login', body_json)
      .then((response) => {
        setSpinner(false)
     
        console.log(response)
        if (response.status !== 200 && response.statusText !== 'OK') {
          // setResponseData(response.data)
          toast.error(response.statusText)
        } else {
          toast.success('Login Successfully')
          setResponseData(response.data)
          console.log(response.data)
          localStorage.setItem('user_token', response.data.token)
         
          setAccess_token(response.data.token)
          Cookies.set('user_token', response.data.token,{
            expires: new Date(Date.now()+25892000000) // Will expire after 30 days from setting (value is in Date object)
           
         })
          
        
          navigate('/myorder')
          window.location.reload()
        }
      })
      .catch((error) => {
        toast.error(error.response.data.msg)
        console.log(error.response.data.msg)
        setErrorData(error.response)  
        setSpinner(false)
        if(error.response.data.email_verified === "email_not_verified"){
          navigate('/emailVerify',{state:{email:email}});
          window.location.reload()
        }
        if(error.response.data.phone_no_verified === "number_not_verified"){
          navigate('/phoneVerify',{state:{phone:phone,country_code: countryMobile}});
          window.location.reload()
        }

        // if (error) {
        //   setErrorData(error.response)
        // console.log('error.response.data.msg')

        //   toast.error(error.response.data.msg)

        //   // navigate('/myorder');
        // }
      })
  
      setCapval(null)
  }
  const handlePhoneChange = (e) => {
    const inputPhone = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (inputPhone.length <= 10) {
      setPhone(inputPhone);
    }
  };
  // let history = useHistory();

  return (
    <>
         <MetaData title={"Navya | SignIn"} canonicalUrl={"https://navyaedu.com/signIn"}/>
       <ToastContainer />
      {spinner ? (
 
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
     
      
      )
      :(
        <section className="login_signup_main_section">
        {/* <ToastContainer /> */}

        <div className="flex">
          <div className="basis-1/2 md:block hidden auth_form_side_img_bg">
            <img src={NavyaImg} alt="" className="img-fluid login_pgae_img" />
          </div>
          <div className="mx-auto p-3 login_main_box">
            <div>
              <Link style={{color:mode=="dark"&&"white"}} to={'/'} className="font-medium auth_back_btn">
                <img src={back_img} className="img-fluid" />
                <span className="px- text-lg"> Back</span>
              </Link>
            </div>
            <div className="font-bold text-2xl pt-4">
              <h1 style={{color:mode=="dark"&&"white"}}>Welcome to Navya® Student Portal </h1>
              <h1 style={{color:mode=="dark"&&"white"}} className='mt-3'>Let's Log In</h1>
            </div>
            <div className="pt-2">
              <p style={{color:mode=="dark"&&"white"}}>Please enter your log in details</p>
              {/* <p style={{color:mode=="dark"&&"white"}}>Enter your mobile number or Email to continue.</p> */}
            </div>

            <div className="pt-10 w-full">
              <div className="pb-2">
                <p className="login_toggle">
                  Login With:{' '}
                  <label style={{ marginLeft: '5px', marginRight: '10px' }}>
                    Email
                  </label>
                  <ReactSwitch checked={checked} onChange={handleChange} />
                  <label style={{ marginLeft: '15px' }}>Mobile Number</label>
                </p>
              </div>

              <div>
                {checked ? (
                  <form onSubmit={handleSubmit}>
                    <div className="border-[#aeaeae] d-flex border rounded p-2">
                      <select
                            className="w-20 border-none focus:border-none focus:outline-none input_box"
                            aria-label="Default select example"
                            onChange={handleCountryChange}
                            value={countryMobile}
                          >
                                  <option value=""> Country</option>
                          { 
                              responsecountryData.map((course) => (
                                <option value={course.dial_code}>
                                  {course.name + '('+course.dial_code+')'}
                                </option>
                              ))
                            }
                        </select>
                      <input
                      value={phone}
                        type="tel"
                        name="phone"
                        className="w-80 border-none focus:border-none focus:outline-none input_box"
                        placeholder="Enter Your Phone Number"
                        style={{color:mode=="dark"&&"white"}}
                        required
                        onChange={handlePhoneChange}
                      />

                        
                        

                    </div>
                    
                    <div className="mt-3 border-[#aeaeae] border rounded p-2 relative">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        className="w-full border-none focus:border-none focus:outline-none input_box"
                        placeholder="Enter Your Password Here"
                        required
                        style={{color:mode=="dark"&&"white"}}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {/* <span className="absolute right-3">
                        <i className="fa fa-eye-slash text-[grey]"></i>
                      </span> */}
                      <InputAdornment position="end" className="show_hide_btn">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                        </IconButton>
                      </InputAdornment>
                    </div>
                    <div className="mt-2 mb-2 text-end select-none">
                      <Link
                        to={'/forgot_password'}
                        className="text-[#FF7474]"
                        style={{ color: '#FF7474' }}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                    <ReCaptcha/>
                    <div className="mt-3">
                      <button
                        type="submit"
                        name=""
                        id=""
                        className="w-full border-none focus:border-none focus:outline-none text-white bg-[#66BE5E] rounded py-3 btn_hover"
                        
                      >
                        Log In
                        </button>
                    </div>
                    {/* <div className='mt-1 bg-[#66BE5E] rounded py-2' >
                  <input type="submit" name="" id="" className='w-full border-none focus:border-none focus:outline-none text-white' value="Log In"  />
                </div>  */}
                  </form>
                ) : (
                  <>
                    <form onSubmit={handleSubmit}>
                      <div className="border-[#aeaeae] border rounded p-2">
                        <input
                        value={email}
                          type="email"
                          name="email"
                          className="w-full border-none focus:border-none focus:outline-none input_box"
                          placeholder="Enter Your Email "
                          required
                          style={{color:mode=="dark"&&"white"}}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="mt-3 border-[#aeaeae] border rounded p-2 relative">
                        <input
                          // type="password"
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          className="w-full border-none focus:border-none focus:outline-none input_box"
                          placeholder="Enter Your Password Here"
                          required
                          style={{color:mode=="dark"&&"white"}}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {/* <span className="absolute right-3">
                          <i className="fa fa-eye-slash text-[grey]"></i>
                        </span> */}
                        <InputAdornment
                          position="end"
                          className="show_hide_btn"
                        >
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                          </IconButton>
                        </InputAdornment>
                      </div>
                      <div className="mt-2 mb-2 text-end">
                        <Link
                          to={'/forgot_password'}
                          className="text-[#FF7474]"
                          style={{ color: '#FF7474' }}
                        >
                          Forgot Password?
                        </Link>
                      </div>
                      <ReCaptcha/>
                      <div className="mt-3">
                        <button
                          type="submit"
                          name=""
                          id=""
                          className="w-full border-none focus:border-none focus:outline-none text-white  bg-[#66BE5E] rounded py-3 btn_hover"
                         
                        >
                          Log In
                        </button>
                      </div>
                      {/* <div className='mt-1 bg-[#66BE5E] rounded py-2' >
                  <input type="submit" name="" id="" className='w-full border-none focus:border-none focus:outline-none text-white' value="Log In"  />
                </div>  */}
                    </form>
                  </>
                )}
              </div>
            </div>
            <div className="py-3 text-center">
              <p className="">
                Don't Have An Account?{' '}
                <span className="text-[#38a32e] font-semibold">
                  <Link to={'/signUp'}>SignUp</Link>
                </span>
              </p>
            </div>
            {/* <div className="pb-3 mt-3 text-center">
              <p className=" py-3 px-3 bg-[white] inline rounded">
                Or Login With
              </p>
            </div> */}

            <div
              className="pb-0 mt-3 text-center flex social_login_btn_width items-center justify-center"
              style={{ gap: '30px',  }}
            >
              <GoogleLogin
                // clientId="YOUR_GOOGLE_CLIENT_ID"
                clientId="1079916508117-mnaan1n9e26fvjn8ngnf0u45v2crc30n.apps.googleusercontent.com"
                render={(renderProps) => (
                  <button
                    className="order_details_box_shadow py-3 px-3 bg-[#e6e6e6] inline rounded"
                    onClick={(e) => handleLoginWithSocialAccount(e, 'google')}
                    // onClick={renderProps.onClick}
                    // disabled={renderProps.disabled}
                  >
                    
                    <img
                      src={require(`../../assets/images/google.png`)}
                      className="inline "
                      alt=""
                      width={25}
                    />
                    {/* ... */}
                  </button>
                )}
                // onSuccess={responseGoogle}
                // onFailure={responseGoogle}
                cookiePolicy="single_host_origin"
              />

              <FacebookLogin
                // appId="YOUR_FACEBOOK_APP_ID"
                appId="755448422802814"
                // autoLoad={false}
                // fields="name,email,picture"
                // callback={responseFacebook}
                render={(renderProps) => (
                  <button
                    className="order_details_box_shadow py-3 px-3 bg-[#e6e6e6] inline rounded"
                    onClick={(e) => handleLoginWithSocialAccount(e, 'facebook')}
                    // onClick={renderProps.onClick}
                  >
                    <img
                      src={require(`../../assets/images/facebook.png`)}
                      className="inline "
                      alt=""
                      width={25}
                    />
                    {/* ... */}
                  </button>
                )}
              />

              {/* <button className="order_details_box_shadow py-3 px-3 bg-[#e6e6e6] inline rounded">
                <img
                  src={require(`../../assets/images/google.png`)}
                  className="inline "
                  alt=""
                  width={25}
                />
              </button>

              <button className="order_details_box_shadow py-3 px-3 bg-[#e6e6e6] inline rounded">
                <img
                  src={require(`../../assets/images/facebook.png`)}
                  className="inline "
                  alt=""
                  width={25}
                />
              </button> */}
            </div>

            {/* <div className="mt-3 mb-3 text-center">
              <Link
                to={'/signUp'}
                className="text-[#FF7474]"
                style={{ color: '#FF7474' }}
              >
                Link Your Social Account
              </Link>
            </div> */}
          </div>
        </div>
      </section>
      )
      }
      
    </>
  )
}

export default StartPage
