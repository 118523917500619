import Logo from "../../assets/images/logo.png";
import white from "../../assets/images/logo-white.png";

import React from "react";
import { Link, unstable_HistoryRouter, useSearchParams  } from "react-router-dom";
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import profile from "../../assets/images/profile/profile.png";

import {

  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { MdKeyboardArrowDown } from "react-icons/md";

import { IoIosArrowForward } from "react-icons/io";

import { userContext } from "../../Context/UserContextProvider";
import ReactHtmlParser from "html-react-parser";
import UseAxios from '../../util/UseAxios'
import LogoutModel from "../modal/LogoutModel";
import ImportCoursePopup from "../modal/coursePopup/ImportCoursePopup";
import Cookies from "js-cookie";
const cate_btn = {
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "16px",
  textTransform: "capitalize",
  color: "#7D7D7D",
  padding: "0",

  fontFamily: "'Outfit', sans-serif",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const {
    contextvalue,
    setContextvalue,
    mode,
    contextcart_length,
    contextwislist_length,
    setContextcart_length,
    wishlistitemslength,
    notification_lenght,
    cartitemslength,
    fetchnotificationData,
  } = React.useContext(userContext);
  const instance = UseAxios()
 
  // let [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();

  const location = useLocation();

  React.useEffect(() => {
    // This function will be executed whenever the location changes
   if(mode==="dark"){
    $("a").removeClass("dark_nav_active");
    $(`a[href="${location.pathname}"]`).addClass("dark_nav_active");
   }else{
    $("a").removeClass("nav_active");
    $(`a[href="${location.pathname}"]`).addClass("nav_active");
   }
  }, [location.pathname]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCourse = (course) => {

    localStorage.setItem("course", course);
    // window.open(window.location.href,'_self')
    // window.location.href = `/course/${course}`;
    setAnchorEl(null);
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  let [responseData, setResponseData] = React.useState("");
  let [errorData, setErrorData] = React.useState("");

  const usertoken = localStorage.getItem("user_token");

  React.useEffect(() => {
    const fetchData = async () => {
      if (location.pathname === "/search") {
        let inp = document.getElementById("search_inp");
        inp.focus();
      }
      try {
        const response = await instance.get("/api/v1/courses/category");
        if (Array.isArray(response.data.categories)) {
          setResponseData(response.data.categories);
          setStoreresponseData(response.data.categories[0].category_name);
          // console.log(response.data.categories);
        } else {
          console.log("newnew");
        }
      } catch (error) {
        setErrorData(error);
      }
    };

    fetchData();
    wishlistitemslength();
    cartitemslength();
    fetchnotificationData();
  }, [usertoken]);

  // logout
  const [Logouteopen, setLogouteOpen] = useState(false);
  const LogouthandleShow = (e) => {
    return e.preventDefault(), setLogouteOpen(true);
  };
  const [storeresponseData, setStoreresponseData] = useState("");

  // click to search courses componment onfocus and onbular author: Nitesh
  //   const [searchresult,setSearchresult]=useState(false)

  // function Showeresult(){
  //   setSearchresult(true)

  // }

  const [inpvaluestore, setStoreinpvalue] = useState("");

  function redirectsearch(e, flag) {
    // setSearchParams({result:e.target.value})
    console.log(e.key);
    if (e.key === "Enter" || flag === "search") {
      let path = `/search?q=${inpvaluestore}`;
      setStoreinpvalue(contextvalue || '');
      navigate(path);
    }
  }
  function SearchInp(e) {
    setContextvalue(e.target.value.toLowerCase());
    setStoreinpvalue(e.target.value);

    // const timer  = setTimeout(()=>{
    //   setContextvalue(e.target.value.toLowerCase());
    // },1000)
    // return ()=>clearTimeout(timer)
    // console.log(e.target.value);
  }

  return (
    <>
      <section className="main_login_header main_haeder">
        <header className="bg-white">
          <nav
            style={{ background: mode == "dark" && "#151515" }}
            className="mx-auto flex max-w-8xl items-center justify-between p-1 lg:px-8  navclass"
            aria-label="Global"
          >
            <div className="flex  items-center ">
              <Link to="/" style={{ color: mode == "dark" && "white" }}>
              <img className="logo" src={mode==="dark"? white :Logo} alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <Popover.Group className="hidden lg:flex lg:gap-x-12"></Popover.Group>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end mid-nav">
              <div className="items-center justify-around   hidden md:flex  mid-nav-inner">
                {usertoken && (
                  <Link
                    style={{ color: mode == "dark" && "white" }}
                    to="/"
                    className=" text-[#7D7D7D] font-[500]  font-Outfit mr-5 menu_links"
                  >
                    Home
                  </Link>
                )}

                <div className="text-[#7D7D7D] font-[500]  font-Outfit mr-5 menu_links">
                  {usertoken ? (
                    <Link
                      style={{ color: mode == "dark" && "white" }}
                      to="/myorder"
                      className=""
                    >
                      Dashboard
                    </Link>
                  ) : (
                    <Link
                      style={{ color: mode == "dark" && "white" }}
                      to="/"
                      className=""
                    >
                      Home
                    </Link>
                  )}
                </div>
                    <ImportCoursePopup/>
            

                {/* <Button
                  className="menu_links mx-2"
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  style={cate_btn}
                >
                  Courses
                 
                </Button> */}

                <div className="text-[#7D7D7D] font-[500]  font-Outfit mr-5 menu_links">
                  <Link
                    style={{ color: mode == "dark" && "white" }}
                    to="/contact-us"
                  >
                    Contact Us
                  </Link>
                </div>
                <div className="text-[#7D7D7D] font-[500]  font-Outfit mr-5 menu_links">
                  <Link
                    style={{ color: mode == "dark" && "white" }}
                    to="/about-us"
                  >
                    About Us
                  </Link>
                </div>
                <div className="text-[#7D7D7D] font-[500]  font-Outfit mr-5 menu_links">
                  <Link
                    style={{ color: mode == "dark" && "white" }}
                    to="https://blog.navyaedu.com/career"
                    target="_blank"
                  >
                    Career
                  </Link>
                </div>

                <div className="header_mainn_button_gap text-[#7D7D7D] font-[500]  font-Outfit mr-5 menu_links">
                  <a
                    style={{ color: mode == "dark" && "white" }}
                    href="/#news"
                    // onclick="return false;"
                  >
                    News
                    
                  </a>
                </div>
                {/* <Link to="/search"> */}
                <div
                  style={{
                    color: mode === "dark" && "#white ",
                    background: mode === "dark" ? "#151515 ":"white"
                  }}
                  className="   border-[1px] header_input_box mx-2 border-[#C0C0C0] rounded-[57.5px] items-center px-2 lg:w-[15rem] md:w-[15rem] hidden md:flex search_desktop"
                >
                  <input
                    style={{
                      color: mode === "dark" && "#white ",
                      background: mode === "dark" && "#151515 ",
                    }}
                    value={inpvaluestore}
                    id="search_inp"
                    onChange={(e) => SearchInp(e)}
                    onKeyDown={redirectsearch}
                    // onBlur={()=>setSearchresult(false)}
                    // onFocus={Showeresult}

                    type="search"
                    className="border-none focus:outline-none p-1 rounded-xl w-full"
                    placeholder="Search Anything"
                  />
                  <i
                    onClick={(e) => redirectsearch(e, "search")}
                    className="fa fa-search px-2 text-[#7D7D7D]"
                  ></i>
                </div>
                {/* </Link> */}
                <div className="text-[#775EA5] mx-1 font-[500]  hidden md:block">
                  <Link
                    style={{ color: mode == "dark" && "white" }}
                    to="/wishlist"
                  >
                    <div className="relative">
                      <i
                        style={{ color: mode == "dark" && "white" }}
                        className="fa fa-heart-o border w-full h-full border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2 btn_hover_round"
                      ></i>
                      {contextwislist_length != "0" && usertoken && (
                        <div className="absolute bg-[#66BE5E] w-5 h-5 rounded-full right-[-8px] top-[-8px] flex justify-center items-center text-white text-xs">
                          {contextwislist_length}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>

                <Link
                  style={{ color: mode == "dark" && "white" }}
                  to="/order_details/"
                  className="text-[#775EA5] mx-2 font-[500] pl-2 hidden md:block"
                >
                  <div className="relative">
                    <i
                      style={{ color: mode == "dark" && "white" }}
                      className="fa fa-shopping-cart border border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2 btn_hover_round"
                    ></i>
                    {contextcart_length != "0" && usertoken && (
                      <div className="absolute bg-[#66BE5E] w-5 h-5 rounded-full right-[-8px] top-[-8px] flex justify-center items-center text-white text-xs">
                        {contextcart_length}
                      </div>
                    )}
                  </div>
                </Link>

                <div className="text-[#775EA5] ml-0 mr-3 font-[500] px-2 ">
                  <Link
                    to="/notification"
                    style={{ color: mode == "dark" && "white" }}
                  >
                    <div className="relative">
                      <i
                        style={{ color: mode == "dark" && "white" }}
                        className="fa fa-bell-o border border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2 btn_hover_round"
                      ></i>
                      {notification_lenght.length != "0" && (
                        <div className="absolute bg-[#66BE5E] w-4 h-4 rounded-full right-[-5px] top-[-5px] flex justify-center items-center text-white text-xs">
                          {notification_lenght.length}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
                {usertoken ? (
                  <div className="">
                    <Link
                      style={{ color: mode == "dark" && "white" }}
                      // onClick={logout}
                      onClick={LogouthandleShow}
                      className="bg-[#66BE5E]  text-white p-2 rounded w-[6rem] text-center font-Outfi hidden md:block btn_hover"
                    >
                      Logout
                    </Link>
                  </div>
                ) : (
                  <div className="">
                    <Link
                      style={{ color: mode == "dark" && "white" }}
                      className="bg-[#66BE5E]  text-white p-2 rounded w-[6rem] text-center font-Outfi hidden md:block btn_hover"
                      to={"/signIn"}
                    >
                      Login
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel
              className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
              style={{ zIndex: "999" }}
            >
              <div className="flex items-center justify-between">
                <Link to="/" style={{ color: mode == "dark" && "white" }}>
                  <img src={Logo} alt="" className="logo_small" />
                </Link>
                <button
                  type="button"
                  className="mr-12 d-none rounded-md p-2.5 text-gray-700 "
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    <div
                      style={{
                        color: mode == "dark" && "white",
                        background: mode === "dark" && "#151515",
                      }}
                      className="bg-white border-[1px] header_input_box border-[#C0C0C0] rounded-[57.5px] items-center px-2 w-[25rem] hidden md:flex search_mobile"
                    >
                      <input
                        style={{
                          color: mode == "dark" && "white",
                          background: mode === "dark" && "#151515",
                        }}
                        type="search"
                        className="border-none focus:outline-none p-2 rounded-xl w-full"
                        placeholder="Search Anything"
                      />
                    </div>

                    <Button
                      className="Courses_Categories_btn"
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      endIcon={<MdKeyboardArrowDown />}
                      style={cate_btn}
                    >
                      Courses Categories
                    </Button>
                    {/* <div className="menu_links">
                      <Link style={{ color: mode == "dark" && "white" }} to="#">
                        Why Navya?
                      </Link>
                    </div> */}
                    <div className=" menu_links">
                      <Link
                        style={{ color: mode == "dark" && "white" }}
                        to="/about-us"
                      >
                        About
                      </Link>
                    </div>
                    <div className=" menu_links">
                      <Link
                        style={{ color: mode == "dark" && "white" }}
                        to="/contact-us"
                      >
                        Contact
                      </Link>
                    </div>
                  </div>
                  <div className="py-6">
                    <div
                      className="text-[#775EA5]  font-[500] px-2 "
                      style={{ display: "inline-block" }}
                    >
                      <Link
                        style={{ color: mode == "dark" && "white" }}
                        to="/wishlist"
                      > 
                      <div className="relative">
                      <i
                          style={{ color: mode == "dark" && "white" }}
                          className="fa fa-heart-o border w-full h-full border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2"
                        ></i>
                      {contextwislist_length != "0" && (
                        <div className="absolute bg-[#66BE5E] w-4 h-4 rounded-full right-[-5px] top-[-5px] flex justify-center items-center text-white text-xs">
                          {contextwislist_length}
                        </div>
                      )}
                    </div>
                        
                      </Link>
                    </div>
                    <div className="text-[#775EA5] font-[500] px-2 "  style={{ display: "inline-block" }}>
                  <Link
                    to="/notification"
                    style={{ color: mode == "dark" && "white" }}
                  >
                    <div className="relative">
                      <i
                        style={{ color: mode == "dark" && "white" }}
                        className="fa fa-bell-o border border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2 btn_hover_round"
                      ></i>
                      {notification_lenght.length != "0" && (
                        <div className="absolute bg-[#66BE5E] w-4 h-4 rounded-full right-[-5px] top-[-5px] flex justify-center items-center text-white text-xs">
                          {notification_lenght.length}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
                    <Link
                      style={{ color: mode == "dark" && "white" }}
                      to="/order_details/"
                      className="text-[#775EA5]  font-[500] px-2 "
                    >
                      <div
                        className="relative"
                        style={{ display: "inline-block" }}
                      >
                        <i
                          style={{ color: mode == "dark" && "white" }}
                          className="fa fa-shopping-cart border border-[#775EA5] text-[#775EA5] font-semibold scale-125 rounded-full p-2"
                        ></i>
                       
                        {contextcart_length != "0" && (
                        <div className="absolute bg-[#66BE5E] w-4 h-4 rounded-full right-[-5px] top-[-5px] flex justify-center items-center text-white text-xs">
                          {contextcart_length}
                        </div>
                      )}
                        
                      </div>
                    </Link>


                    <div className="mt-6">
                   {!usertoken && <div className="bg-[#66BE5E] w-full  text-white my-4 rounded  text-center font-Outfi">
                      <Link
                        style={{ color: mode == "dark" && "white" }}
                        className="bg-[#66BE5E] d-flex justify-center items-center text-white  py-2  rounded  text-center font-Outfi "
                        to={"/signIn"}
                      >
                        Login
                      </Link>
                    </div>}
                    <div className="" onClick={() => setMobileMenuOpen(false)}>
                     <button className="bg-[#D3D3D3] w-full text-white p-2 px-5 rounded  text-center font-Outfi  ">

                        Close menu
                     </button>
                     
                    </div>

                    </div>
                    
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </section>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className=" main_header_dropdown"
      >
        <div
          className="bg-[#f5f5f5]"
          style={{ background: "green !important" }}
        >
          {Array.isArray(responseData) ? (
            responseData.map((category) => (
              <div className="menu_data col-lg-12 col-md-6 col-sm-12 col-6 ">
                <div className="flex header_cata">
                  <div
                    className="header_cata_heading "
                    onMouseEnter={() =>
                      setStoreresponseData(category.category_name)
                    }
                  >
                    <h2
                      className="header_cate_name "
                      style={{ background: mode === "dark" && "black" }}
                    >
                      <div className="Course_title_flex">
                        <i>
                          <img
                            src={
                              category.category_image === "" ||
                              category.category_image === undefined ||
                              category.category_image === null
                                ? "./plceholdernav/placeholder-img.png"
                                : category.category_image
                            }
                            width={30}
                          ></img>
                        </i>

                        {RegExp("&lt;").test(category.category_name)
                          ? ReactHtmlParser(
                              `${category.category_name.replaceAll(
                                "&lt;",
                                "<"
                              )}`
                            )
                          : category.category_name}
                      </div>
                      <i>
                        <IoIosArrowForward />
                      </i>
                    </h2>
                  </div>

                  {category.category_name === storeresponseData && (
                    <div
                      className="header_cate_name_children grid grid-cols-4 gap-4 w-[75%] absolute right-0 p-4"
                      style={{ background: mode === "dark" && "black" }}
                    >
                      {category.courses.map((course, index) => {
                        return (
                          <div
                            className="header_cata_content overflow-hidden bg-[#fff] header_coursees_name"
                            key={index}
                            style={{ background: mode === "dark" && "#151515" }}
                          >
                            <Link
                              style={{ color: mode == "dark" && "white" }}
                              to={`/course/${course._id}`}
                              onClick={() => handleCourse(course._id)}
                              className=" py-1 mt-2  rounded flex justify-around items-center header_coursees_name_anchor"
                              
                            >
                              <div className="Course_title_flex ">
                                <img
                                  src={
                                    course.course_image === "" ||
                                    course.course_image === null ||
                                    course.course_image === undefined
                                      ? "./plceholdernav/placeholder-img.png"
                                      : `${course.course_image}`
                                  }
                                  width={20}
                                ></img>
                                <div className="white_space ps-0">
                                  {RegExp("&lt;").test(course?.course_title)
                                    ? ReactHtmlParser(
                                        `${course?.course_title.replaceAll(
                                          "&lt;",
                                          "<"
                                        )}`
                                      )
                                    : course?.course_title}
                                </div>
                              </div>
                              <i>
                                <IoIosArrowForward />
                              </i>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p style={{ color: mode == "dark" && "white" }}>
              No courses available.
            </p>
          )}
        </div>
      </Menu>

      {Logouteopen && <LogoutModel open={Logouteopen}  setOpen={setLogouteOpen}/> }
      {/* {searchresult && <Searchresulte showedata={"search"}/>} */}
    </>
  );
};

export default Navbar;
