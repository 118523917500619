import React, {Fragment, useRef, useState } from 'react'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import { useNavigate } from 'react-router'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { MdExpandMore } from "react-icons/md";
import ReactHtmlParser from "html-react-parser";
import Typography from "@mui/material/Typography";
import { userContext } from '../../Context/UserContextProvider'
function Faq() {
  const { mode, setMode } = React.useContext(userContext);
  const navigate = useNavigate()
  const usertoken = localStorage.getItem('user_token')
  const [spinner, setSpinner] = React.useState(false);
    let [faqresponseData, setFaqresponseData] = React.useState([])
    let [faqerrorData, setFaqerrorData] = React.useState('')
  
    React.useEffect(() => {
      const fetchData = async () => {
        setSpinner(true)
  
        try {
          const instance = axios.create({
            baseURL: 'https://backend.navyaedu.com/',
            headers: {
              Authorization: 'Bearer ' + usertoken,
            },
          })
  
          const response = await instance.get('/api/v1/app/faq')
          // const data = await response.data
          console.log(response.data)
          console.log('faqCategory');
          setFaqresponseData(response.data)
          setSpinner(false)
        } catch (error) {
          setSpinner(false)
          setFaqerrorData(error)
        }
      }
  
      fetchData()
    }, [usertoken])
  return (
    <section className=" tabs_side_gap">
    <div className="w-[100%] m-auto other_tabs_headings">
      <h2>Frequently Asked Questions</h2>
      {/* {
          faqresponseData.map((maintitle,index)=>{
            return( */}
      <div className="according_box">
        {faqresponseData.map((faq, index) => {
          return (
            <div key={index}>
            
              <Accordion className="rounded-none">
                <AccordionSummary
                  expandIcon={<MdExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={mode === "dark"? "!bg-[#000] border mb-1 group" :"!bg-[#775da8da] border mb-1 group"}
                >
               
                  <Typography sx={{color: mode === "dark" && "#fff"}} className={mode === "dark" 
                  ? 
                  " font-extrabold text-uppercase !text-[#fff] group-hover:!text-[#000]" 
                  :
                  "text-black font-extrabold text-uppercase text-white group-hover:!text-[#775da8] font-extrabold"}>
                   

                    {ReactHtmlParser(`${faq._id.replaceAll("&lt;", "<")}`)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {faq.faqs.map((faqQA, index) => {
                    return (
                      <Accordion key={index} className="p-0">
                        <AccordionSummary
                          expandIcon={<MdExpandMore />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                          className={
                            mode === "dark"
                              ? "!bg-[#000] group1"
                              : "group1"
                          }
                        >
                          <Typography
                            sx={{
                              
                              bgColor: mode === "dark" && "#000",
                            }}
                            className={
                              mode === "dark"
                                ? " !text-[#fff] group-hover:!text-[#000]"
                                : ""
                            }
                          >
                            {ReactHtmlParser(
                              `${faqQA?.question.replaceAll("&lt;", "<")}`
                            )}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          className={
                            mode === "dark"
                              ? "!bg-[#000] border mb-1 group2"
                              : " group2"
                          }
                        >
                          <Typography
                            sx={{
                              color: mode === "dark" && "#fff",
                              bgColor: mode === "dark" && "#000",
                            }}
                            className={
                              mode === "dark"
                                ? " !text-[#fff] group2-hover:!text-[#000] !pl-8"
                                : " !pl-8"
                            }
                          >
                            {ReactHtmlParser(
                              `${faqQA?.answer.replaceAll("&lt;", "<")}`
                            )}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      </div>
    </div>
  </section>
  )
}

export default Faq